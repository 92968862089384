import { useTranslation } from 'next-i18next';

import { selectContactsMap } from 'slices/contactsSlice';
import { useAppSelector } from '../../../../app/hooks';
import { selectVisibleProposals } from 'slices/proposalsSlice';
import { selectIsAdminOrOwner, selectCurrentWorkspace } from 'slices/workspacesSlice';
import { selectUserProfile } from 'slices/userSlice';
import { orderBy } from 'lodash';

export default function useSearchProposals({
  searchQuery,
  filteredMembers,
}: {
  searchQuery: string;
  filteredMembers: string[];
}) {
  const { t } = useTranslation();
  const title = t('proposals:sectionName');
  const { email } = useAppSelector(selectUserProfile);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const contactsMap = useAppSelector(selectContactsMap);
  const visibleProposals = useAppSelector(selectVisibleProposals);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);
  const searchQueryIncludesCol = title.toLowerCase().includes(searchQuery);

  const getSearchedVisibleProposals = () => {
    if (searchQuery.length === 0 || searchQueryIncludesCol) {
      return visibleProposals;
    }

    return visibleProposals.filter((proposal) => {
      const contact = contactsMap[proposal.contactId];
      const contactTags = contact.tags.map(({ name }) => name);
      const contactPhoneNumber = contact.phones[0].normalized_phone;
      const contactName = contact ? contact.suggestion_name || contact.name : '';
      const contactJob = contact ? contact.suggestion_job_title || contact.job_title : '';
      const contactCompany = contact ? contact.suggestion_company || contact.company : '';

      const title = proposal.title;
      const client = proposal.client;

      const fieldsForSearch = [
        contactPhoneNumber,
        contactName,
        contactJob,
        contactCompany,
        ...contactTags,
        title,
        client,
      ];
      const combinedSearchText = fieldsForSearch.join(';').toLowerCase();
      return combinedSearchText.includes(searchQuery);
    });
  };

  const searchedVisibleProposals = getSearchedVisibleProposals();
  const searchedFilteredProposals = filteredMembers.length
    ? searchedVisibleProposals.filter((proposal) => {
        const { assigned_to } = contactsMap[proposal.contactId];
        return filteredMembers.includes(assigned_to);
      })
    : searchedVisibleProposals;

  const shouldFilterByMemberRole = !isAdmin && !memberAccessAllContacts;
  const filteredByRole = shouldFilterByMemberRole
    ? searchedFilteredProposals.filter((proposal) => {
        const contact = contactsMap[proposal.contactId];

        return contact.assigned_to === email;
      })
    : searchedFilteredProposals;

  return {
    visibleProposals: orderBy(filteredByRole, 'createdAt', 'desc'),
    shouldHideColumn:
      searchQuery.length > 0 && filteredByRole.length === 0 && !searchQueryIncludesCol,
  };
}
