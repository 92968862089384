import { Draggable } from 'react-beautiful-dnd';
import { useState, useRef, memo } from 'react';
import { VariableSizeList } from 'react-window';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../../../styles/calls/board.module.css';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import clsx from 'clsx';
import ShareIcon from '../../../../../public/images/icons/share.svg';
import ColumnMenu from '../components/ColumnMenu';
import { IconButton, Typography } from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AddIcon from 'icons/add-user-rounded.svg';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from 'next-i18next';

import VirtualDroppable from './VirtualDroppable/VirtualDroppable';
import StandardDroppable from './StandardDroppable';
import { hideCallsBatch } from 'slices/callsSlice';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import { useSnackbar } from 'notistack';
import { ColumnModel, ItemModel } from '../../../../api/boardAPI';
import { useAppDispatch, useAppSelector, useAppStore } from '../../../../app/hooks';
import { actions } from '../../../../slices/boardSlice';
import { selectIsAdminOrOwner } from '../../../../slices/workspacesSlice';
import { selectContactsMap } from '../../../../slices/contactsSlice/slice';
import {
  makeSelectProposalsByContact,
  actions as proposalsActions,
} from '../../../../slices/proposalsSlice';

import UpdateColumnForm from './UpdateColumnForm';
import ContactsDialog from './ContactsDialog';
import Tooltip from 'components/UI/Tooltip';
import useDuplicateContact from '../../../contacts/hooks/useDuplicateContact';
import MoreIcon from 'components/UI/Icons/MoreIcon';
import { trackEvent } from 'src/analytics/amplitude';
import NoTaggedContacts from '../../../contacts/Board/Column/NoTaggedContacts/NoTaggedContacts';

const Column = ({
  column,
  index,
  disabledDrag,
  isRow,
  shouldVirtualize,
  virtualListsRefsMap,
  scrollContainerHeight,
  isBoardEmpty,
}: {
  column: ColumnModel;
  index: number;
  disabledDrag: boolean;
  isRow: boolean;
  shouldVirtualize: boolean;
  virtualListsRefsMap: React.MutableRefObject<Record<string, VariableSizeList>>;
  scrollContainerHeight: number;
  isBoardEmpty?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const store = useAppStore();

  const duplicateContact = useDuplicateContact();

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openForm, setIsOpenForm] = useState(false);
  const { t } = useTranslation(['calls-page', 'common']);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleEdit = () => {
    if (isAdmin) {
      setIsOpenForm((prevState) => !prevState);
    }
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getContactId = (selectedId: string) => {
    const contactsMap = selectContactsMap(store.getState());
    const contact = contactsMap[selectedId];

    if (contact.workspace_id === null) {
      const duplicatedContactId = uuidv4();

      duplicateContact(selectedId, { uuid: duplicatedContactId }).catch(() => {
        dispatch(actions.setSyncFailed());
      });

      return duplicatedContactId;
    } else {
      return selectedId;
    }
  };

  const handleChange = ({ type, uuid }: { type: 'add' | 'remove'; uuid: string }) => {
    withErrorSnackbar({
      onTry: async () => {
        const contactsMap = selectContactsMap(store.getState());
        const contact = contactsMap[uuid];
        const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
        dispatch(hideCallsBatch(contactPhones));
      },
      enqueueSnackbar,
    });
    if (type == 'add') {
      const contactId = getContactId(uuid);
      const selectProposalsByContact = makeSelectProposalsByContact();
      const proposalIds = selectProposalsByContact(store.getState(), contactId)
        .filter(({ visible }) => visible)
        .map(({ uuid }) => uuid);

      if (proposalIds.length > 0) {
        dispatch(proposalsActions.hideProposals(proposalIds));
      }

      const item: ItemModel = {
        type: 'contact',
        typeUuid: contactId,
        uuid: uuidv4(),
        columnUuid: column.id,
        createdAt: Math.floor(Date.now() / 1000),
        updatedAt: Math.floor(Date.now() / 1000),
      };

      dispatch(
        actions.addItem({
          columnUuid: column.id,
          item,
        })
      );
    } else if (type == 'remove') {
      const item = column.items.find((item) => item.typeUuid === uuid);
      dispatch(actions.deleteItems({ columnUuid: column.id, itemsUuids: [item.uuid] }));
    }
    trackEvent('Move_to', { action: 'Plus button of list' });
  };

  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled={disabledDrag}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={
              snapshot.isDragging
                ? clsx({
                    [styles.columnDrag]: true,
                    [styles.column]: true,
                    [styles.columnEmpty]: isBoardEmpty && index === 0,
                  })
                : clsx({
                    [styles.column]: true,
                    [styles.columnEmpty]: isBoardEmpty && index === 0,
                  })
            }
          >
            <ContactsDialog
              anchorEl={anchorEl}
              selected={column.items.map((item) => item.typeUuid)}
              onChange={handleChange}
              onClose={handleCloseDialog}
            />
            <div className={clsx(styles.columnHeader, 'drop-header')} style={{ top: 60 }}>
              <header
                style={{
                  color: column.color,
                  display: 'flex',
                  fontSize: 18,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {/* eslint-disable-next-line */}
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleEdit}>
                  <span>{column.title}</span>
                  <div style={{ position: 'relative', padding: '2px 10px', marginLeft: 10 }}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 11,
                        color: column.color,
                        lineHeight: 3.5,
                      }}
                    >
                      {column.items.length}
                    </span>
                    <div
                      style={{
                        borderRadius: 10,
                        position: 'absolute',
                        width: '100%',
                        height: 20,
                        background: column.color,
                        opacity: 0.3,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                </div>
                <UpdateColumnForm
                  open={openForm}
                  onClose={() => setIsOpenForm(false)}
                  uuid={column.id}
                  title={column.title}
                  color={column.color}
                />
                {isRow ? (
                  <div className={styles.rowActions}>
                    <button className={styles.action} onClick={handleOpenDialog}>
                      <PersonAddOutlinedIcon />
                      <Typography component={'span'}>
                        {t('duplicates:Add_contact_action')}
                      </Typography>
                    </button>
                    <div className={styles.action}>
                      <ImportExportIcon />
                      <Typography component={'span'}>
                        {t('ColumnMenu.export_short', { ns: 'calls-page' })}
                      </Typography>
                    </div>
                    <div className={styles.action}>
                      <ShareIcon />
                      <Typography component={'span'}>
                        {t('ColumnMenu.share_short', { ns: 'calls-page' })}
                      </Typography>
                    </div>
                    <IconButton
                      ref={anchorRef}
                      className={styles.laneActionButton}
                      onClick={handleToggle}
                      disabled={!isAdmin}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <ColumnMenu
                      columnId={column.id}
                      isOpen={isOpen}
                      anchorRef={anchorRef}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                ) : (
                  <div className={styles.columnHeaderButton}>
                    <Tooltip placement="top" title={t('duplicates:Add_contact_action')}>
                      <IconButton className={styles.laneActionButton} onClick={handleOpenDialog}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={t('tooltips.calls_contacts_more', { ns: 'common' })}
                    >
                      <IconButton
                        ref={anchorRef}
                        className={styles.laneActionButton}
                        onClick={handleToggle}
                        disabled={!isAdmin}
                      >
                        <MoreIcon className={styles.IconHover} />
                      </IconButton>
                    </Tooltip>
                    <ColumnMenu
                      columnId={column.id}
                      isOpen={isOpen}
                      anchorRef={anchorRef}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                )}
              </header>
            </div>
            {isBoardEmpty && index === 0 && <NoTaggedContacts />}
            {!!(isRow && column.items.length && !snapshot.isDragging) && (
              <div className={styles.columnTableHeader}>
                <Typography component={'span'}>#</Typography>
                <Typography className={styles.headerItemName} component={'span'}>
                  {t('RowHeaders.name', { ns: 'calls-page' })}
                </Typography>
                <Typography className={styles.headerItemTags} component={'span'}>
                  {t('RowHeaders.tags', { ns: 'calls-page' })}
                </Typography>
                <Typography className={styles.headerItemStatus} component={'span'}>
                  {t('RowHeaders.status', { ns: 'calls-page' })}
                </Typography>
                <Typography className={styles.headerItemNotes} component={'span'}>
                  {t('RowHeaders.notes', { ns: 'calls-page' })}
                </Typography>
              </div>
            )}

            {(!snapshot.isDragging || (snapshot.isDragging && !isRow)) && (
              <div className={styles.columnCardList}>
                {shouldVirtualize ? (
                  <VirtualDroppable
                    {...column}
                    ref={(ref) => (virtualListsRefsMap.current[column.id] = ref)}
                    scrollContainerHeight={scrollContainerHeight}
                  />
                ) : (
                  <StandardDroppable isBoardEmpty={isBoardEmpty} {...column} />
                )}
              </div>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};

export default memo(Column);
