import { useTranslation } from 'next-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';

import Avatar from '../../../../components/UI/Avatar';
import normalizePhoneNumber from '../../../../utils/PhoneHelper';
import { useAppSelector } from '../../../../app/hooks';
import { selectContacts } from '../../../../slices/contactsSlice';
import { calcTotal } from '../../../../slices/proposalsSlice';

import boardStyles from '../../../../styles/calls/board.module.css';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

const ProposalRow = ({
  proposalData,
  onContextMenu,
}: {
  proposalData: ProposalEntity;
  onContextMenu: (x: number, y: number) => void;
  menuIsOpen: boolean;
}) => {
  const { t } = useTranslation('proposals');

  const contacts = useAppSelector(selectContacts);
  const relatedContact = contacts[proposalData.contactId];

  const { total } = calcTotal({
    items: proposalData.items,
    includesTax: proposalData.settings.includesTax,
    taxRate: proposalData.settings.taxRate,
    discountAmount: proposalData.discountAmount,
    discountType: proposalData.discountType,
  });

  return (
    <tr>
      <td>
        <Avatar
          thumbnail={relatedContact.thumbnail}
          name={relatedContact.suggestion_name || relatedContact.name}
        />
      </td>
      <td>
        {relatedContact?.suggestion_name ||
          relatedContact.name ||
          normalizePhoneNumber(relatedContact?.phones[0].phone)}
      </td>
      <td className={boardStyles.number}>{proposalData.number}</td>
      <td className={boardStyles.title}>{proposalData.title}</td>
      <td className={boardStyles.sent}>
        {proposalData.isDraft
          ? t('Card.draft')
          : new Date(proposalData.createdAt).toLocaleDateString(undefined, {
              month: 'long',
              day: 'numeric',
            })}
      </td>
      <td className={boardStyles.total}>
        {proposalData.settings.currency} {total.toFixed(2)}
      </td>
      <td className={boardStyles.contextMenu}>
        <IconButton onClick={(event) => onContextMenu(event.clientX, event.clientY)}>
          <MoreHorizIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default ProposalRow;
