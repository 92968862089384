import { useAppSelector } from '../../../app/hooks/useAppSelector';
import { selectContactsMap } from '../../../slices/contactsSlice';
import {
  selectIsAdminOrOwner,
  selectCurrentWorkspace,
  selectCurrentUserMemberData,
} from '../../../slices/workspacesSlice';

export default function useHasPermissionToDragItem() {
  const contactsMap = useAppSelector(selectContactsMap);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const currentUserMemberData = useAppSelector(selectCurrentUserMemberData);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);

  return (contactId: string) => {
    const isAssignedToCurrentUser =
      contactsMap[contactId]?.assigned_to === currentUserMemberData.email;

    const hasPermission = isAdmin || memberAccessAllContacts || isAssignedToCurrentUser;

    return hasPermission;
  };
}
