import { forwardRef, useRef, useLayoutEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { VariableSizeList } from 'react-window';

import { ColumnModel } from '../../../../../api/boardAPI';
import { DragTypes } from '../../index';
import { useAppStore } from '../../../../../app/hooks';
import { selectContactsMap } from '../../../../../slices/contactsSlice';
import { selectIsCurrentWorkspaceCollaborative } from '../../../../../slices/workspacesSlice';

import styles from './VirtualDroppable.module.css';
import Row from './Row';
import Card from '../../Card';
import { selectContactTags } from 'slices/tagsBoardSlice';

const VirtualDroppable = forwardRef<
  VariableSizeList,
  ColumnModel & { scrollContainerHeight: number }
>(function VirtualDroppable(props, ref) {
  const store = useAppStore();
  const innerRef = useRef<VariableSizeList>();

  const { id, items, scrollContainerHeight } = props;

  const calculateItemsHeight = () => {
    const contactsMap = selectContactsMap(store.getState());
    const isCurrentWorkspaceCollaborative = selectIsCurrentWorkspaceCollaborative(store.getState());

    return items.map(({ typeUuid }) => {
      const contact = contactsMap[typeUuid];
      const contactTags = selectContactTags(contact?.uuid)(store.getState());

      const baseHeight = 64;
      const marginBottom = 10;

      const tagsHeight = 18;
      const tagsMarginTop = 7;
      let height = baseHeight + marginBottom;
      if (contactTags.length > 0) {
        height += tagsHeight + tagsMarginTop;
      }

      const assignToSection = 43;

      if (isCurrentWorkspaceCollaborative) {
        height += assignToSection;
      }

      return height;
    });
  };

  const sizes = calculateItemsHeight();

  const getItemSize = (index: number) => {
    return sizes[index] || 64;
  };

  useLayoutEffect(() => {
    // VariableSizeList caches offsets and measurements for each index for performance purposes.
    // this effect updates measures after items ordering
    innerRef.current.resetAfterIndex(0, true);
  }, [items]);

  return (
    <Droppable
      droppableId={id}
      key={id}
      type={DragTypes.card}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => {
        const item = items[rubric.source.index];

        return (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Card columnId={item.columnUuid} uuid={item.uuid} contactUuid={item.typeUuid} />
          </div>
        );
      }}
    >
      {(provided) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        // for more details check the react-beautiful-dnd doc
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/virtual-lists.md
        const itemCount: number = items.length + 1;

        return (
          <VariableSizeList
            overscanCount={5}
            ref={(instance) => {
              innerRef.current = instance;

              // actually the ref prop is a function, but here is an error "This expression is not callable."
              (ref as any)(instance);
            }}
            height={scrollContainerHeight}
            itemCount={itemCount}
            itemSize={getItemSize}
            width={365}
            outerRef={provided.innerRef}
            itemData={items}
            className={styles.VirtualList}
            itemKey={(index, data) => {
              const extraEmptyItem = !data[index];
              if (extraEmptyItem) {
                return `${index}_extra`;
              }
              return data[index].uuid;
            }}
          >
            {Row}
          </VariableSizeList>
        );
      }}
    </Droppable>
  );
});

export default VirtualDroppable;
