import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
}

const MoreIcon: React.FC<IProps> = ({ className }) => {
  return (
    <div className={clsx(styles.Icon, className)}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default MoreIcon;
