import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/calls/board.module.css';
import { selectBoard, actions } from '../../../../slices/boardSlice';
import { patchManyContacts, selectContactsMap } from '../../../../slices/contactsSlice';
import {
  selectIsAdminOrOwner,
  selectIsCurrentWorkspaceCollaborative,
} from '../../../../slices/workspacesSlice';

import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import DeleteIcon from '../../../../../public/images/icons/delete.svg';
import { useAppDispatch, useAppSelector, useAppStore } from '../../../../app/hooks';
import DeleteDialog from '../../../../components/UI/Dialogs/DeleteDialog';
import { trackEvent } from 'src/analytics/amplitude';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import ImportButton from 'src/features2/importCSV/ui/components/ImportContactsButton';
const ColumnMenu = ({ isOpen, setIsOpen, anchorRef, columnId }) => {
  const { t } = useTranslation('calls-page');

  const { enqueueSnackbar } = useSnackbar();

  const store = useAppStore();

  const dispatch = useAppDispatch();
  const board = useAppSelector(selectBoard);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const isCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);

  const column = board.columns.find((colItem) => colItem.id === columnId);

  const [dialogType, setDialogType] = useState<'removeAllItems' | 'removeColumn' | undefined>();
  const dialogIsOpen = !!dialogType;

  const closeDialog = () => setDialogType(undefined);

  const dialogText = {
    removeAllItems: {
      title: t('ColumnMenu.dialogs.remove_all_items.title'),
      description: t('ColumnMenu.dialogs.remove_all_items.description', { title: column.title }),
    },
    removeColumn: {
      title: t('duplicates:Delete_list_action'),
      description: isCollaborative
        ? t('ColumnMenu.dialogs.remove_column.description_collaborative')
        : t('ColumnMenu.dialogs.remove_column.description', { title: column.title }),
    },
  };

  const handleDialogSuccess = async () => {
    if (dialogType === 'removeAllItems') {
      removeAllItems();
    } else if (dialogType === 'removeColumn') {
      trackEvent('Delete_status');

      if (isCollaborative && column.items.length !== 0) {
        const state = store.getState();
        const contactsMap = selectContactsMap(state);

        const contactsToArchive = column.items
          .map(({ typeUuid }) => {
            const contact = contactsMap[typeUuid];
            return contact?.uuid;
          })
          .filter((uuid) => !!uuid)
          .map((uuid) => ({
            uuid,
            is_archived: true,
            updated_at: Math.floor(Date.now() / 1000),
          }));

        try {
          const failed = await dispatch(patchManyContacts(contactsToArchive)).unwrap();

          if (failed.length > 0) {
            throw new Error('There are not update contacts');
          }

          dispatch(actions.deleteColumn(columnId));
        } catch {
          enqueueSnackbar({
            message: t('common:message.common_error'),
            variant: MessageType.Error,
          });
        }
      } else {
        dispatch(actions.deleteColumn(columnId));
      }
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setIsOpen(false);
  };

  const removeAllItems = async () => {
    const itemsUuids = board.columns
      .find((colItem) => colItem.id === columnId)
      .items.map((item) => item.uuid);

    dispatch(actions.deleteItems({ columnUuid: columnId, itemsUuids }));
  };

  const handleRemoveColumn = async () => {
    setDialogType('removeColumn');

    setIsOpen(false);
  };

  return (
    <>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-start'}
        style={{ zIndex: 1001 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={styles.columnMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  {isAdmin && (
                    <ImportButton
                      props={{ columnId }}
                      inputId={'status'}
                      // onClick={handleClose} // [LOW] TODO: don't work cause if we close menu because input rendered inside disappear
                    />
                  )}
                  {isAdmin && (
                    <MenuItem onClick={handleRemoveColumn}>
                      <DeleteIcon />
                      {t('duplicates:Delete_list_action')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {dialogIsOpen && (
        <DeleteDialog
          title={dialogText[dialogType].title}
          description={dialogText[dialogType].description}
          open={dialogIsOpen}
          onClose={closeDialog}
          onSuccess={handleDialogSuccess}
          deleteButtonText={t('common:action.remove')}
        />
      )}
    </>
  );
};
export default ColumnMenu;
