import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { DropResult } from 'react-beautiful-dnd';
import { useAppStore } from '../../../app/hooks';
import { selectCalls } from '../../../slices/callsSlice';
import {
  selectCurrentUserMemberData,
  selectMemberDataByEmail,
} from '../../../slices/workspacesSlice';
import { filterByAnchors, selectContactsArray } from '../../../slices/contactsSlice';

export default function useReassignCallDialogState() {
  const { t } = useTranslation('calls-page');

  const store = useAppStore();

  const [state, setState] = useState<null | {
    contactName: string;
    teammateName: string;
  }>(null);
  const promiseResolverRef = useRef<() => void>(null);

  const [callReassingDialogOpen, setCallReassingDialogOpen] = useState(false);

  const checkShouldReassignCall = async (result: DropResult) => {
    const noChange = !result.destination;
    if (noChange) {
      return Promise.resolve();
    }

    if (result.source.droppableId == 'column-calls') {
      const contacts = selectContactsArray(store.getState());
      const calls = selectCalls(store.getState());

      const callId = result.draggableId;
      const call = calls.find(({ id }) => id === callId);

      const { email } = selectCurrentUserMemberData(store.getState());

      const relatedContact = filterByAnchors(contacts).find((contactItem) =>
        contactItem.phones.some((phoneItem) => phoneItem.normalized_phone === call.normalizedPhone)
      );

      if (relatedContact && !!relatedContact.assigned_to && relatedContact.assigned_to !== email) {
        const teammate = selectMemberDataByEmail(relatedContact.assigned_to)(store.getState());

        setState({
          contactName:
            relatedContact.suggestion_name ||
            relatedContact.name ||
            relatedContact.phones?.[0].phone,
          teammateName: teammate.fullName || teammate.email,
        });
        setCallReassingDialogOpen(true);

        return new Promise((resolve) => {
          promiseResolverRef.current = resolve as () => void;
        });
      } else {
        return Promise.resolve();
      }
    }
  };

  const handleConfirm = () => {
    if (typeof promiseResolverRef.current === 'function') {
      setState(null);
      setCallReassingDialogOpen(false);

      promiseResolverRef.current();
    }
  };

  return {
    callReassingDialogOpen,
    checkShouldReassignCall,
    closeReassignCallDialog: () => {
      setState(null);
      setCallReassingDialogOpen(false);
    },
    description: t('reassign_call_dialog.description', {
      contact_name: state?.contactName,
      teammate_name: state?.teammateName,
    }),
    handleConfirm,
  };
}
