import React, { Fragment } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Divider from '@material-ui/core/Divider';

import styles from '../../../styles/calls/callsColumn.module.scss';
import CallsItem from './components/CallsItem';
import { DragTypes } from '../Board';
import CallEntity from 'src/db/entities/call/CallEntity';

const renderClone = (calls) => {
  const CloneItem = (provided, snapshot, rubric) => {
    const item = calls[rubric.source.index];
    return (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <CallsItem call={item} isDragging={true} />
      </div>
    );
  };
  return CloneItem;
};

const CallsColumnFilled = ({ calls }: { calls: CallEntity[] }) => {
  return (
    <Droppable
      renderClone={renderClone(calls)}
      droppableId={'column-calls'}
      isDropDisabled={true}
      type={DragTypes.card}
    >
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} className={styles.callsColumn}>
            {calls.map((item, index) => {
              const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
              return (
                <Fragment key={item.id}>
                  {shouldRenderClone ? (
                    <div className={styles.CallsItemCopy}>
                      <CallsItem call={item} isDragging={false} />
                      <Divider className={styles.divider} />
                    </div>
                  ) : (
                    <>
                      <Draggable draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CallsItem call={item} isDragging={false} />
                          </div>
                        )}
                      </Draggable>
                      <Divider className={styles.divider} />
                    </>
                  )}
                </Fragment>
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};
export default CallsColumnFilled;
