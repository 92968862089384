import { useInjection } from '../../app/ioc/ioc.react';
import { ISyncStateRepository } from '../../features2/sync/domain/abstractions/ISyncStateRepository';
import { TYPES } from '../../features2/sync/di/types';
import { useBehaviorSubject } from '../../app/hooks/rx';

import Board from './Board';
import styles from '../../styles/calls/index.module.css';

const Calls = () => {
  const syncStateRepository = useInjection<ISyncStateRepository>(TYPES.SyncStateRepository);
  const [restoreStatus] = useBehaviorSubject(syncStateRepository.restoreStatus);
  const restored = restoreStatus === 'restored';

  return <div className={styles.callsWrapper}>{restored && <Board />}</div>;
};
export default Calls;
