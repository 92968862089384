import type { NextPage, GetServerSideProps } from 'next';
import Calls from '../features/calls';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const IndexPage: NextPage = () => {
  return <Calls />;
};
export const getServerSideProps: GetServerSideProps = async ({ locale = 'en' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'billing',
        'calls-page',
        'proposals',
        'proposal-form',
        'proposal-settings',
        'contact-details',
        'contacts-page',
        'add-contact-page',
        'search',
        'tasks-page',
        'notifications',
        'dialogs',
        'import-csv',
        'settings',
        'files',
        'duplicates',
        'customFields',
      ])),
      // Will be passed to the page component as props
    },
  };
};
export default IndexPage;
