import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { selectContactsMap } from '../../../../slices/contactsSlice';
import { useAppStore, useAppSelector } from '../../../../app/hooks';
import { ColumnModel } from 'src/api/boardAPI';
import { selectContactTags } from 'slices/tagsBoardSlice';
import contactService from '../../../../services/contacts/contactService';

export default function useSearch(boardColumns: ColumnModel[]): {
  selection: ColumnModel[];
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
} {
  const contactsMap = useAppSelector(selectContactsMap);
  const store = useAppStore();
  const [searchQuery, setSearchQuery] = useState('');

  const searchOptionsMap = useMemo(() => {
    return boardColumns.reduce((acc, column) => {
      const mapBoardItems = column.items.reduce((accItems, item) => {
        const contact = contactsMap[item.typeUuid];
        const contactTags = selectContactTags(item.typeUuid)(store.getState()).map(
          ({ title }) => title
        );
        if (!contact) {
          return accItems;
        }
        const contactPhoneNumber = contact?.phones?.[0]?.normalized_phone;
        const contactName = contactService.getContactName(contact);
        const contactJob = contact ? contact.suggestion_job_title || contact.job_title : '';
        const contactCompany = contact ? contact.suggestion_company || contact.company : '';
        const fieldsForSearch = [
          contactPhoneNumber,
          contactName,
          contactJob,
          contactCompany,
          ...contactTags,
        ];
        const combinedSearchText = fieldsForSearch.join(';').toLowerCase();
        accItems[item.uuid] = combinedSearchText;
        return accItems;
      }, {});
      acc[column.id] = mapBoardItems;
      return acc;
    }, {});
  }, [contactsMap, boardColumns]);

  const searchQueryTrimmed = searchQuery.trim().toLowerCase();
  let selection = boardColumns;

  if (searchQueryTrimmed) {
    const searchedBoardColumns = [];

    boardColumns.forEach((column) => {
      const searchedColumnItems = column.items.filter((item) => {
        const combinedSearchText = searchOptionsMap[column.id][item.uuid];
        return combinedSearchText?.includes(searchQueryTrimmed);
      });

      if (searchedColumnItems.length) {
        searchedBoardColumns.push({ ...column, items: searchedColumnItems });
        return;
      }

      if (column.title.toLowerCase().includes(searchQueryTrimmed)) {
        searchedBoardColumns.push(column);
      }
    });

    selection = searchedBoardColumns;
  }

  return { selection, searchQuery: searchQueryTrimmed, setSearchQuery };
}
