import { CSSProperties } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { areEqual } from 'react-window';
import { memo } from 'react';

import ProposalItem from '../../ProposalItem';
import useHasPermissionToDragItem from '../../../useHasPermissionToDragItem';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

function getStyle({
  draggableStyle,
  virtualStyle,
}: {
  draggableStyle: CSSProperties;
  virtualStyle: CSSProperties;
}) {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  const marginBottom = 10;

  const result = {
    ...combined,
    height: (combined.height as number) - marginBottom,
    left: combined.left,
    width: '100%',
    marginBottom: marginBottom,
  };

  return result;
}

const Row = memo(function Row(props: {
  data: ProposalEntity[];
  index: number;
  style: CSSProperties;
}) {
  const { data: items, index, style } = props;
  const item = items[index];

  const hasPermissionToDragItem = useHasPermissionToDragItem();

  // We are rendering an extra item for the placeholder
  // for more details check the react-beautiful-dnd doc
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/virtual-lists.md
  if (!item) {
    return null;
  }

  const isDragDisabled = !hasPermissionToDragItem(item.contactId);

  return (
    <Draggable
      key={item.uuid}
      draggableId={item.uuid}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            key={item.uuid}
            style={getStyle({
              draggableStyle: provided.draggableProps.style,
              virtualStyle: style,
            })}
          >
            <ProposalItem isRow={false} key={item.uuid} proposalData={item} />
          </div>
        );
      }}
    </Draggable>
  );
},
areEqual);

export default Row;
