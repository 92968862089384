import { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveIcon from '../../../../../public/images/icons/remove-items.svg';
import ProposalIcon from '../../../../../public/images/icons/price-proposal.svg';
import UserIcon from '../../../../../public/images/icons/user.svg';

import CopyIcon from '@material-ui/icons/FileCopy';

import { useTranslation } from 'next-i18next';
import callsColumnStyles from '../../../../styles/calls/callsColumn.module.scss';
import { useProposalDrawer } from '../../../proposals/ProposalDrawer';
import { useAppDispatch } from '../../../../app/hooks';
import { actions } from '../../../../slices/proposalsSlice';
import DeleteDialog from '../../../../components/UI/Dialogs/DeleteDialog';
import { setCurrentContact } from 'slices/contactsSlice';
import useHasPermissionToDragItem from '../useHasPermissionToDragItem';
import { trackEvent } from 'src/analytics/amplitude';
import { useQuotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const ProposalCardMenu = ({
  uuid,
  contactUuid,
  position,
  onClose,
}: {
  uuid: string;
  contactUuid: string;
  position: { mouseX: number | null; mouseY: number | null };
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { checkLimits } = useQuotesLimits();

  const { t } = useTranslation('proposals');

  const { openUpdateForm, openDuplicateForm, close } = useProposalDrawer();

  const anchorPosition =
    position.mouseY !== null && position.mouseX !== null
      ? { top: position.mouseY, left: position.mouseX }
      : undefined;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleViewContact = () => {
    dispatch(setCurrentContact(contactUuid));
  };

  const hasPermissionToDragItem = useHasPermissionToDragItem();
  const hasNoPermission = !hasPermissionToDragItem(contactUuid);

  return (
    <>
      <Menu
        open={position.mouseY !== null}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        {!hasNoPermission && (
          <>
            <MenuItem
              className={callsColumnStyles.recentCallsMenuItem}
              onClick={() => {
                openUpdateForm({ uuid, onSuccess: close });
                onClose();
              }}
            >
              <ProposalIcon />
              {t('duplicates:Edit_action')}
            </MenuItem>
            <MenuItem
              className={callsColumnStyles.recentCallsMenuItem}
              onClick={() => {
                checkLimits() && openDuplicateForm({ uuid, onSuccess: close });
                onClose();
              }}
            >
              <CopyIcon />
              {t('common:action.duplicate')}
            </MenuItem>
          </>
        )}

        <MenuItem className={callsColumnStyles.recentCallsMenuItem} onClick={handleViewContact}>
          <UserIcon />
          {t('Card.Menu.viewContact')}
        </MenuItem>
        <Divider />
        {!hasNoPermission && (
          <MenuItem
            className={callsColumnStyles.recentCallsMenuItem}
            onClick={() => {
              setDeleteDialogOpen(true);
            }}
          >
            <RemoveIcon />
            {t('duplicates:Delete_action')}
          </MenuItem>
        )}
      </Menu>
      <DeleteDialog
        title={t('Card.Menu.deleteDialog.title')}
        description={t('Card.Menu.deleteDialog.description')}
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          onClose();
        }}
        onSuccess={() => {
          trackEvent('Delete_quote');
          dispatch(actions.deleteProposals([uuid]));
        }}
      />
    </>
  );
};

export default ProposalCardMenu;
