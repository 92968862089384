import { forwardRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { VariableSizeList } from 'react-window';

import { useAppStore } from '../../../../../../app/hooks/useAppStore';
import ProposalItem from '../../ProposalItem';
import Row from './Row';
import { DragTypes } from '../../../index';
import { selectIsCurrentWorkspaceCollaborative } from '../../../../../../slices/workspacesSlice';

import styles from './VirtualDroppable.module.css';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

interface VirtualDroppable {
  columnId: string;
  visibleProposals: ProposalEntity[];
  scrollContainerHeight: number;
}

const VirtualDroppable = forwardRef<VariableSizeList, VirtualDroppable>(function VirtualDroppable(
  props,
  ref
) {
  const { visibleProposals, columnId } = props;
  const store = useAppStore();

  const calculateItemsHeight = () => {
    const isCurrentWorkspaceCollaborative = selectIsCurrentWorkspaceCollaborative(store.getState());

    return visibleProposals.map(() => {
      const baseHeight = 80;
      const marginBottom = 10;

      let height = baseHeight + marginBottom;

      const assignToSection = 43;

      if (isCurrentWorkspaceCollaborative) {
        height += assignToSection;
      }

      return height;
    });
  };

  const sizes = calculateItemsHeight();

  const getItemSize = (index: number) => {
    return sizes[index] || 64;
  };
  return (
    <Droppable
      droppableId={columnId}
      key={columnId}
      type={DragTypes.card}
      isDropDisabled={true}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => {
        const item = visibleProposals[rubric.source.index];

        return (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <ProposalItem isRow={false} proposalData={item} />
          </div>
        );
      }}
    >
      {(provided) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        // for more details check the react-beautiful-dnd doc
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/virtual-lists.md
        const itemCount: number = visibleProposals.length + 1;

        return (
          <VariableSizeList
            overscanCount={5}
            ref={ref}
            height={sizes.reduce((acc, itemSize) => (acc += itemSize + 20), 0)}
            itemCount={itemCount}
            itemSize={getItemSize}
            width={365}
            outerRef={provided.innerRef}
            itemData={visibleProposals}
            className={styles.VirtualList}
            itemKey={(index, data) => {
              const extraEmptyItem = !data[index];
              if (extraEmptyItem) {
                return `${index}_extra`;
              }

              return data[index].uuid;
            }}
          >
            {Row}
          </VariableSizeList>
        );
      }}
    </Droppable>
  );
});

export default VirtualDroppable;
