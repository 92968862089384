import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { updateColumn } from '../../../../slices/boardSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';

import styles from '../../../../styles/calls/board.module.css';
import ColumnAttributesForm, {
  ColumnAttributesFormProps,
} from '../../../../components/UI/Board/ColumnAttributesForm/ColumnAttributesForm';
import { trackEvent } from 'src/analytics/amplitude';

const UpdateColumnForm = ({
  open,
  onClose,
  title,
  color,
  uuid,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  color: string;
  uuid: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(['common', 'calls-page']);
  const dispatch = useAppDispatch();

  const handleSubmit: ColumnAttributesFormProps['onSubmit'] = async (props) => {
    trackEvent('Edit_status', { type: props.title !== title ? 'color' : 'name' });
    try {
      await dispatch(updateColumn({ uuid, title: props.title, color: props.color })).unwrap();

      onClose();
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };

  return (
    <ColumnAttributesForm
      className={styles.editColumn}
      open={open}
      defaultColor={color}
      inputDefaultValue={title}
      inputPlaceholder={t('calls-page:BoardForm.placeholder')}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default UpdateColumnForm;
