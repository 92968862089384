import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { DropResult } from 'react-beautiful-dnd';
import { hideCallsBatch } from 'slices/callsSlice';

import { useAppDispatch, useAppStore } from '../../../app/hooks';
import {
  actions as boardActions,
  makeSelectBoardColumnsWithVisibleContacts,
  selectBoardColumns,
} from '../../../slices/boardSlice';
import {
  patchWorkspaceOptimistic,
  selectCurrentWorkspaceId,
  selectProposalsColNextId,
} from '../../../slices/workspacesSlice';

import { DragTypes } from './index';
import { MessageType } from '../../../components/UI/snackbar/providers/SnackBarProvider';
import useCreateProposalFromBoardItem from './Proposals/useCreateProposalFromBoardItem';
import useCreateItemByCall from './useCreateItemByCall';
import useCreateProposalFromCall from './Proposals/useCreateProposalFromCall';
import useCreateBoardItemFromProposal from './Proposals/useCreateBoardItemFromProposal';
import { ColumnModel, ItemModel } from 'src/api/boardAPI';
import { trackEvent } from 'src/analytics/amplitude';
import { selectContactsMap } from 'slices/contactsSlice';

function useUpdateBoardByDrag() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const columns = selectBoardColumns(store.getState());
  const contactsMap = selectContactsMap(store.getState());
  const boardItemsMap = columns.reduce<{ [key: string]: ItemModel }>((acc, column) => {
    column.items.forEach((item) => {
      acc[item.uuid] = item;
    });
    return acc;
  }, {});
  const createItemByCall = useCreateItemByCall();
  const createProposalFromCall = useCreateProposalFromCall('Drag to quotes list');

  const createProposalFromBoardItem = useCreateProposalFromBoardItem('Drag to quotes list');

  const createBoardItemFromProposal = useCreateBoardItemFromProposal();

  return async (
    { source, destination, draggableId, type }: DropResult,
    preparedColumns: ColumnModel[],
    proposalsColHidden: boolean
  ) => {
    const noChange = !destination;
    if (noChange) {
      return;
    }

    const state = store.getState();
    const columns = makeSelectBoardColumnsWithVisibleContacts(state);

    try {
      if (type === DragTypes.column) {
        const getCurrentOrder = () => {
          const order = preparedColumns.map(({ id }) => id);
          const proposalsColNextId = selectProposalsColNextId(state);

          if (!proposalsColHidden) {
            if (proposalsColNextId === null) {
              order.push('proposals');
            } else {
              const nextColIndex = preparedColumns.findIndex(
                (col) => col.id === proposalsColNextId
              );

              if (nextColIndex === -1) {
                order.push('proposals');
              } else {
                order.splice(nextColIndex, 0, 'proposals');
              }
            }
          }

          return order;
        };
        const currentOrder = getCurrentOrder();
        const workspaceId = selectCurrentWorkspaceId(state);

        if (draggableId === 'proposals-column') {
          const nextId =
            destination.index === currentOrder.length - 1
              ? null
              : preparedColumns[destination.index].id;

          dispatch(
            patchWorkspaceOptimistic({
              id: workspaceId,
              data: { proposalsNextColId: nextId, updatedAt: Date.now() },
            })
          );
        } else {
          const prevVisibleColumnId = currentOrder[destination.index - 1];
          const proposalsIndex = currentOrder.indexOf('proposals');

          const prevVisibleColumn =
            prevVisibleColumnId === 'proposals'
              ? preparedColumns.find(({ id }) => id === currentOrder[proposalsIndex - 1])
              : preparedColumns.find(({ id }) => id === prevVisibleColumnId);

          let newColumnIndex = 0;
          if (prevVisibleColumn) {
            newColumnIndex = columns.findIndex((column) => column.id === prevVisibleColumn.id);
            newColumnIndex += 1;
          }

          if (
            (currentOrder.indexOf(draggableId) < currentOrder.indexOf('proposals') &&
              destination.index >= currentOrder.indexOf('proposals')) ||
            (currentOrder.indexOf('proposals') == currentOrder.length - 1 &&
              destination.index == currentOrder.indexOf('proposals'))
          ) {
            dispatch(
              boardActions.moveColumn({ uuid: draggableId, destIndex: destination.index - 1 })
            );
            if (destination.index == currentOrder.indexOf('proposals')) {
              dispatch(
                patchWorkspaceOptimistic({
                  id: workspaceId,
                  data: { proposalsNextColId: draggableId, updatedAt: Date.now() },
                })
              );
            }
          } else if (
            currentOrder.indexOf(draggableId) > currentOrder.indexOf('proposals') &&
            destination.index == currentOrder.indexOf('proposals') + 1
          ) {
            dispatch(boardActions.moveColumn({ uuid: draggableId, destIndex: newColumnIndex }));
            dispatch(
              patchWorkspaceOptimistic({
                id: workspaceId,
                data: { proposalsNextColId: draggableId, updatedAt: Date.now() },
              })
            );
          } else {
            dispatch(boardActions.moveColumn({ uuid: draggableId, destIndex: newColumnIndex }));

            const proposalsColNextId = selectProposalsColNextId(state);

            if (draggableId === proposalsColNextId) {
              const proposalsNextColId = currentOrder[source.index + 1] || null;
              dispatch(
                patchWorkspaceOptimistic({
                  id: workspaceId,
                  data: { proposalsNextColId, updatedAt: Date.now() },
                })
              );
            } else if (proposalsColNextId === null && newColumnIndex + 1 === currentOrder.length) {
              dispatch(
                patchWorkspaceOptimistic({
                  id: workspaceId,
                  data: { proposalsNextColId: draggableId, updatedAt: Date.now() },
                })
              );
            }
          }
        }
      } else if (type === DragTypes.card) {
        if (source.droppableId == 'column-calls') {
          if (destination.droppableId === `${DragTypes.proposal}-outer`) {
            createProposalFromCall(draggableId);
          } else {
            const destVisibleColumn = preparedColumns.find(
              (column) => column.id === destination.droppableId
            );
            const prevVisibleItem = destVisibleColumn.items[destination.index - 1];
            let newItemIndex = 0;
            if (prevVisibleItem) {
              const destColumn = columns.find((column) => column.id === destination.droppableId);
              newItemIndex = destColumn.items.findIndex(
                (item) => item.uuid === prevVisibleItem.uuid
              );
              newItemIndex += 1;
            }
            await createItemByCall({
              callId: draggableId,
              destColId: destination.droppableId,
              destIndex: newItemIndex,
            });
          }
        } else if (destination.droppableId === `${DragTypes.proposal}-outer`) {
          if (source.droppableId !== 'proposal') {
            createProposalFromBoardItem(draggableId, source.droppableId);
          }
        } else if (source.droppableId === DragTypes.proposal) {
          createBoardItemFromProposal(draggableId, destination.droppableId, destination.index);
        } else {
          const contactId = boardItemsMap[draggableId].typeUuid;
          const contact = contactsMap[contactId];
          const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);

          if (source.droppableId === destination.droppableId) {
            if (source.index !== destination.index) {
              const visibleColumn = preparedColumns.find(
                (column) => column.id === source.droppableId
              );

              const prevVisibleItem = visibleColumn.items[destination.index - 1];

              let newItemIndex = 0;
              if (prevVisibleItem) {
                const destColumn = columns.find((column) => column.id === destination.droppableId);
                newItemIndex = destColumn.items.findIndex(
                  (item) => item.uuid == prevVisibleItem.uuid
                );
                newItemIndex += 1;
              }
              dispatch(
                boardActions.changeItemOrder({
                  columnUuid: source.droppableId,
                  itemUuid: draggableId,
                  index: newItemIndex,
                })
              );
            }
            dispatch(hideCallsBatch(contactPhones));
          } else {
            dispatch(
              boardActions.moveItem({
                itemUuid: draggableId,
                sourceColumnUuid: source.droppableId,
                destColumnUuid: destination.droppableId,
              })
            );
            const visibleColumn = preparedColumns.find(
              (column) => column.id === destination.droppableId
            );

            const prevVisibleItem = visibleColumn.items[destination.index - 1];
            let newItemIndex = 0;
            if (prevVisibleItem) {
              const destColumn = columns.find((column) => column.id === destination.droppableId);
              newItemIndex = destColumn.items.findIndex(
                (item) => item.uuid == prevVisibleItem.uuid
              );
              newItemIndex += 1;
            }
            dispatch(
              boardActions.changeItemOrder({
                columnUuid: destination.droppableId,
                itemUuid: draggableId,
                index: newItemIndex,
              })
            );
          }
        }
      }
      trackEvent('Move_to', { action: 'Drag & Drop' });
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };
}

export default useUpdateBoardByDrag;
