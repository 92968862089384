import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppStore } from '../../../../app/hooks';

import { actions as proposalsActions, selectProposals } from '../../../../slices/proposalsSlice';
import { actions as boardActions } from '../../../../slices/boardSlice';
import { ItemModel } from '../../../../api/boardAPI';

export default function useCreateBoardItemFromProposal() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  return (proposalUuid: string, destColumnUuid: string, destIndex: number) => {
    const state = store.getState();
    const currentProposal = selectProposals(state).find(({ uuid }) => uuid === proposalUuid);
    const proposalsToHide = state.proposals.list
      .filter(
        (proposalItem) =>
          proposalItem.contactId === currentProposal.contactId && proposalItem.visible
      )
      .map(({ uuid }) => uuid);

    dispatch(proposalsActions.hideProposals(proposalsToHide));

    const uuid = uuidv4();
    const item: ItemModel = {
      type: 'contact',
      typeUuid: currentProposal.contactId,
      uuid: uuid,
      columnUuid: destColumnUuid,
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
    };

    dispatch(boardActions.addItem({ columnUuid: destColumnUuid, item }));

    dispatch(
      boardActions.changeItemOrder({
        columnUuid: destColumnUuid,
        itemUuid: uuid,
        index: destIndex,
      })
    );
  };
}
