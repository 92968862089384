import { useTranslation } from 'next-i18next';
import React, { useState, useMemo, memo } from 'react';
import clsx from 'clsx';
import hexAlpha from 'hex-alpha';
import { useCallDate } from '../../../../app/hooks/useCallDate';
import styles from '../../../../styles/calls/callsColumn.module.scss';
import { v4 as uuidv4 } from 'uuid';
import IncomingIcon from '../../../../../public/images/icons/incoming-call-sm.svg';
import OutgoingIcon from '../../../../../public/images/icons/outgoing-call-sm.svg';
import MissedIcon from '../../../../../public/images/icons/missed-call-sm.svg';
import ContactCard from '../../../../components/UI/ContactCard';
import BoardCard from '../../../../components/UI/Board/BoardCard/BoardCard';
import {
  makeSelectContactByNormalizedPhone,
  setCurrentContact,
  createContact,
  selectCurrentContact,
} from '../../../../slices/contactsSlice';
import { selectContactTags } from 'slices/tagsBoardSlice';
import { selectBoardContactsMap, selectBoardColumns } from 'slices/boardSlice';
import { makeSelectProposalsByContact } from '../../../../slices/proposalsSlice';
import {
  selectCurrentUserMemberData,
  selectAcceptedTeamMembers,
} from '../../../../slices/workspacesSlice';
import { useAppDispatch, useAppSelector, useAppStore } from '../../../../app/hooks';
import contactService from 'src/services/contacts/contactService';
import useFormatNumber from 'src/app/hooks/ui/useFormatNumber';
import AssignToSelect from '../../../collaboration/components/AssignToSelect/AssignToSelect';
import useAssignContact from '../../../collaboration/hooks/useAssignContact';
import ContactContextDialog from 'components/UI/Dialogs/ContactContextDialog';
import { externalSetIsColumnExpanded } from 'components/UI/CallsContactsColumn';
import CallEntity from 'src/db/entities/call/CallEntity';

const typeIcons = {
  INCOMING: <IncomingIcon />,
  OUTGOING: <OutgoingIcon />,
  MISSED: <MissedIcon />,
  REJECTED: <MissedIcon />,
};

const CallsItem = ({ call, isDragging }: { call: CallEntity; isDragging: boolean }) => {
  const { t } = useTranslation('calls-page');
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const selectContactByNormalizedPhone = useMemo(makeSelectContactByNormalizedPhone, []);
  const callContact = useAppSelector((state) =>
    selectContactByNormalizedPhone(state, call.normalizedPhone)
  );
  const relatedTags = useAppSelector(selectContactTags(callContact?.uuid));

  const currentContact = useAppSelector(selectCurrentContact);
  const [position, setPosition] = useState(null);
  const menuIsOpen = !!position?.x && !!position?.y;

  const boardContactsMap = useAppSelector(selectBoardContactsMap);
  const { email } = useAppSelector(selectCurrentUserMemberData);
  const options = useAppSelector(selectAcceptedTeamMembers);

  const assignContact = useAssignContact('Main tabs');

  const checkIsCurrentContact = () => {
    if (!currentContact) {
      return false;
    }

    return currentContact.uuid === callContact?.uuid;
  };

  const callDate = useCallDate(call.callDate);
  const handleClick = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX - 2,
      y: event.clientY - 4,
    });
  };
  const { formatNumber } = useFormatNumber();

  const handleView = async () => {
    if (window.innerWidth <= 1280) {
      externalSetIsColumnExpanded(false);
    }

    if (callContact) {
      dispatch(setCurrentContact(callContact?.uuid));
    } else {
      const uuid = uuidv4();
      await dispatch(
        createContact({
          uuid,
          name: call.callerInfo.name,
          job_title: call.callerInfo.jobTitle,
          is_spammer: !!call.callerInfo.spam,
          thumbnail: call.callerInfo.thumbnail,
          phones: [
            {
              type: 'mobile',
              phone: call.phoneNumber,
              custom_label: null,
              normalized_phone: call.normalizedPhone,
            },
          ],
        })
      );
      dispatch(setCurrentContact(uuid));
    }
  };

  const hasRelatedProposal = useMemo(() => {
    if (!callContact) {
      return false;
    }

    const state = store.getState();
    const selectProposalsByContact = makeSelectProposalsByContact();
    const proposals = selectProposalsByContact(state, callContact?.uuid).filter(
      ({ visible }) => visible
    );

    return proposals.length > 0 ? true : false;
  }, [callContact, store]);

  if (callContact?.not_show) return null;

  const isSpam = !!call.callerInfo?.spam;

  const nameToShow = callContact?.suggestion_name || callContact?.name || call.callerInfo?.name;
  const jobTitle =
    callContact?.suggestion_job_title || callContact?.job_title || call.callerInfo?.jobTitle;

  const isMissedCall = call.type === 'MISSED' || call.type === 'REJECTED';
  const isArchived = callContact?.is_archived;

  const status = boardContactsMap[callContact?.uuid];
  const shouldShowAssignedTo = callContact && callContact?.assigned_to !== email;
  const getNameEndAdornment = () => {
    let statusObj;
    if (status) {
      const columns = selectBoardColumns(store.getState());
      const currentCol = columns.find((col) => col.id === status.columnUuid);
      statusObj = { title: currentCol.title, color: currentCol.color };
    }
    if (hasRelatedProposal) {
      statusObj = { title: t('proposals:sectionName'), color: '#ff6d39' };
    }
    if (isArchived) {
      statusObj = { title: t('common:tags.archive'), color: '#000' };
    }
    return (
      <div className={styles.nameEndAdornment}>
        {isSpam && ` (${t('common:spam')}) `}
        {statusObj && (
          <span style={{ color: statusObj.color, background: hexAlpha(statusObj.color, 0.2) }}>
            {statusObj.title}
          </span>
        )}
      </div>
    );
  };
  return (
    <>
      <BoardCard
        onClick={handleView}
        onContextMenu={handleClick}
        className={clsx(
          styles.CallsItem,
          isDragging && styles.CallsItemDragging,
          (menuIsOpen || checkIsCurrentContact()) && styles.CallsItemMenuOpen
        )}
      >
        <ContactCard className={styles.CallsItemBody}>
          <ContactCard.Details
            className={styles.ContactDetails}
            thumbnail={contactService.getContactImage(callContact)}
            name={nameToShow}
            nameEndAdornment={getNameEndAdornment()}
            phoneNumber={formatNumber(call.phoneNumber, call.normalizedPhone)}
            jobTitle={jobTitle}
            badges={relatedTags.map((tag) => {
              return { title: tag.title, color: tag.color };
            })}
            isSpammer={isSpam}
            nameClassName={clsx(isMissedCall && styles.missedCallPhoneNumber)}
          >
            {shouldShowAssignedTo && (
              // eslint-disable-next-line
              <div onClick={(event) => event.stopPropagation()} className={styles.assignToSection}>
                <span className={styles.text}>{t('common:collaboration.assignee')}:</span>
                <AssignToSelect
                  options={options}
                  value={callContact?.assigned_to}
                  onSelect={(email) => assignContact(callContact?.uuid, email)}
                  buttonClassName={styles.assignToSelect}
                />
              </div>
            )}
          </ContactCard.Details>
          <div className={styles.CallsItemAdditional}>
            <ContactCard.Actions phoneNumber={call.phoneNumber} />
            <div className={clsx(styles.callDate, shouldShowAssignedTo && styles.callDateMiddle)}>
              <span>{typeIcons[call.type]}</span>
              {callDate}
            </div>
          </div>
        </ContactCard>
      </BoardCard>
      <ContactContextDialog
        callsBoardData={{
          itemId: call.id,
        }}
        contactId={callContact?.uuid}
        position={position}
        onClose={() => setPosition(null)}
      />
    </>
  );
};
export default memo(CallsItem);
