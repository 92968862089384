import Image from 'next/image';
import { Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import Link from 'next/link';

import styles from '../../../styles/calls/callsColumn.module.scss';
import * as gtag from '../../../../lib/gtag';
import { useAppSelector } from '../../../app/hooks';
import { selectUserHasApp } from 'slices/userSlice';

const CallsColumnEmpty = () => {
  const { t } = useTranslation('calls-page');
  const isAppDownloaded = useAppSelector(selectUserHasApp);

  useEffect(() => {
    gtag.event({
      value: 'Empty',
      action: 'Contacts_need_download_the_app',
      category: 'Calls',
      label: 'Contacts_need_download_the_app',
    });
  }, []);

  return (
    <>
      {!isAppDownloaded && (
        <div className={styles.containerNoApp}>
          <Image src={'/images/EmptyCalls.webp'} width={300} quality={100} height={320} />
          <Typography className={styles.noAppDescription}>{t('CallsEmpty.downloadApp')}</Typography>
          <div className={styles.downloadApp}>
            <Link href={process.env.NEXT_PUBLIC_APP_ANDROID_APP_LINK_TO_DOWNLOAD}>
              <a target={'_blank'} rel={'noopener noreferrer'}>
                <IconButton className={styles.downloadButton} disableRipple>
                  <Image
                    quality={100}
                    src={'/images/google-download.webp'}
                    width={115}
                    height={40}
                  ></Image>
                </IconButton>
              </a>
            </Link>
            <Link href={process.env.NEXT_PUBLIC_APP_IOS_APP_LINK_TO_DOWNLOAD}>
              <a target={'_blank'} rel={'noopener noreferrer'}>
                <IconButton className={styles.downloadButton} disableRipple>
                  <Image
                    quality={100}
                    src={'/images/apple-download.webp'}
                    width={115}
                    height={40}
                  ></Image>
                </IconButton>
              </a>
            </Link>
          </div>
        </div>
      )}
      <Image src={'/images/column-empty.png'} width={255} height={145} />
      <div className={styles.NoCallsText}>{t('CallsEmpty.no_calls_text')}</div>
    </>
  );
};
export default CallsColumnEmpty;
