import clsx from 'clsx';
import { useState, memo } from 'react';

import styles from '../../../styles/calls/board.module.css';
import { useAppSelector } from '../../../app/hooks';
import { selectContactsMap, selectCurrentContact, setCurrentContact } from 'slices/contactsSlice';
import { selectIsCurrentWorkspaceCollaborative } from 'slices/workspacesSlice';
import ContactCard from '../../../components/UI/ContactCard';
import BoardCard from '../../../components/UI/Board/BoardCard/BoardCard';
import { selectContactTags } from 'slices/tagsBoardSlice';
import { useDispatch } from 'react-redux';
import contactService from 'src/services/contacts/contactService';
import useFormatNumber from 'src/app/hooks/ui/useFormatNumber';
import ContactContextDialog from 'components/UI/Dialogs/ContactContextDialog';

const Card = ({
  columnId,
  uuid,
  contactUuid,
}: {
  columnId: string;
  uuid: string;
  contactUuid: string;
}) => {
  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);

  const contacts = useAppSelector(selectContactsMap);
  const dispatch = useDispatch();
  const relatedContact = contacts[contactUuid];
  const contactTags = useAppSelector(selectContactTags(contactUuid));
  const currentContact = useAppSelector(selectCurrentContact);
  const [position, setPosition] = useState(null);
  const menuIsOpen = !!position?.x && !!position?.y;
  const isCurrentContactOpened = relatedContact?.uuid === currentContact?.uuid;
  const { formatNumber } = useFormatNumber();

  const onContextClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setPosition({
      x: event.clientX - 2,
      y: event.clientY - 4,
    });
  };

  const onClick = () => {
    dispatch(setCurrentContact(relatedContact.uuid));
  };

  //TODO: it should be removed after implementation all contacts loading after login
  if (!relatedContact) {
    return null;
  }

  const phoneNumberResult = relatedContact?.phones?.[0]?.phone
    ? relatedContact.phones[0].type === 'mobile'
      ? formatNumber(relatedContact.phones?.[0].phone, relatedContact.phones[0].normalized_phone)
      : relatedContact.phones[0].phone
    : '';

  return (
    <>
      <BoardCard
        onClick={onClick}
        onContextMenu={onContextClick}
        className={clsx(
          styles.BoardCard,
          (menuIsOpen || isCurrentContactOpened) && styles.BoardCardMenuOpen
        )}
      >
        <div className={styles.BoardCardInner}>
          <ContactCard className={styles.ContactCard}>
            <ContactCard.Details
              name={contactService.getContactName(relatedContact)}
              thumbnail={contactService.getContactImage(relatedContact)}
              phoneNumber={phoneNumberResult}
              jobTitle={relatedContact.suggestion_job_title || relatedContact.job_title}
              badges={contactTags.map(({ title, color }) => ({ title, color }))}
              isSpammer={relatedContact.is_spammer}
            />
            <ContactCard.Actions phoneNumber={phoneNumberResult} />
          </ContactCard>
          {isCurrentWorkspaceCollaborative && (
            <ContactCard.AssignToSection
              contactId={relatedContact.uuid}
              assignedTo={relatedContact.assigned_to}
            />
          )}
        </div>
      </BoardCard>
      <ContactContextDialog
        callsBoardData={{
          itemId: uuid,
          columnId,
          isArchiveToast: true,
        }}
        position={position}
        onClose={() => setPosition(null)}
        contactId={contactUuid}
      />
    </>
  );
};

export default memo(Card);
