import { Droppable, Draggable } from 'react-beautiful-dnd';

import Card from '../Card';
import { ColumnModel } from '../../../../api/boardAPI';
import { DragTypes } from '../index';
import useSetColumnStyles from 'src/app/hooks/useSetColumnStyles';

import useHasPermissionToDragItem from '../useHasPermissionToDragItem';

export default function StandardDroppable(props: ColumnModel & { isBoardEmpty: boolean }) {
  const { id, items, isBoardEmpty } = props;

  const hasPermissionToDragItem = useHasPermissionToDragItem();
  useSetColumnStyles(items, isBoardEmpty);

  return (
    <Droppable droppableId={id} key={id} type={DragTypes.card} mode="standard">
      {(provided) => {
        return (
          <div
            {...provided.droppableProps}
            data-element-type={'drop-wrapper'}
            ref={provided.innerRef}
            style={{
              minWidth: 365,
            }}
            className={'leader-droppable-column'}
          >
            {items.map((item, index) => {
              const isDragDisabled = !hasPermissionToDragItem(item.typeUuid);

              return (
                <Draggable
                  key={item.uuid}
                  draggableId={item.uuid}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        key={item.uuid}
                      >
                        <Card columnId={id} uuid={item.uuid} contactUuid={item.typeUuid} />
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}
