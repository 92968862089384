import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import uniqBy from 'lodash/uniqBy';
import MUIDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../styles/common/DialogClear.module.css';
import { useSnackbar } from 'notistack';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';

import { useTranslation } from 'next-i18next';
import { useAppDispatch, useAppStore } from 'src/app/hooks';
import { hideCallsBatch, selectCalls } from 'slices/callsSlice';
import CallEntity from 'src/db/entities/call/CallEntity';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-root': {
      width: '50%',
      '& .MuiButton-label': {
        color: '#5f48e9',
        fontSize: 17,
        textTransform: 'capitalize',
        fontWeight: 400,
      },
    },
  },
}))(MuiDialogActions);

const Dialog = ({ open, setIsOpen, calls }) => {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const { t } = useTranslation('calls-page');
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClear = async () => {
    try {
      const calls = selectCalls(store.getState());
      const phones = uniqBy<CallEntity>(calls, 'normalizedPhone').map(
        (call) => call.normalizedPhone
      );
      const result = await dispatch(hideCallsBatch(phones));
      //@ts-ignore
      if (result.requestStatus === 'rejected') throw result.error;
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');
      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };
  return (
    <div>
      <MUIDialog
        className={styles.DialogClear}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'xs'}
      >
        <DialogContent>
          <Typography gutterBottom className={styles.title}>
            {t('Popups.ClearHistory.title')}
          </Typography>
          <Typography gutterBottom className={styles.description}>
            {t('Popups.ClearHistory.description')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('duplicates:Cancel_action')}</Button>
          <Button onClick={handleClear}>{t('Popups.ClearHistory.succeed_button')}</Button>
        </DialogActions>
      </MUIDialog>
    </div>
  );
};
export default Dialog;
