import { useState, useRef, useContext, useCallback, memo, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { VariableSizeList } from 'react-window';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import ShareIcon from '../../../../../../public/images/icons/share.svg';
import ProposalColumnMenu from '../ProposalColumnMenu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import styles from '../../../../../styles/calls/board.module.css';
import { useAppSelector } from '../../../../../app/hooks';
import { useProposalDrawer } from '../../../../proposals/ProposalDrawer';
import { DragTypes, DraggingContext } from '../../index';
import columnStyles from './ProposalColumn.module.scss';
import StandardDroppable from './StandardDroppable';
import VirtualDroppable from './VirtualDroppable/VirtualDroppable';
import { selectIsAdminOrOwner } from 'slices/workspacesSlice';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import { trackEvent } from 'src/analytics/amplitude';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../../../utils';
import LocalStorageKeys from '../../../../../enums/LocalStorageKeys';

const ProposalColumn = ({
  list,
  hidden,
  isRow,
  index,
  shouldVirtualize,
  virtualListsRefsMap,
  scrollContainerHeight,
  disabledDrag,
}: {
  list: ProposalEntity[];
  hidden: boolean;
  isRow: boolean;
  index: number;
  disabledDrag: boolean;
  shouldVirtualize: boolean;
  virtualListsRefsMap: React.MutableRefObject<Record<string, VariableSizeList>>;
  scrollContainerHeight: number;
}) => {
  const { t } = useTranslation();
  const columnId = 'proposal';
  const columnColor = '#ff6d39';
  const title = t('proposals:sectionName');
  const context = useContext(DraggingContext);
  const isDraggingFromProposal = context?.source.droppableId === 'proposal';
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isHiddenByUser, setIsHiddenByUser] = useState(undefined);
  const toggleMenu = () => {
    setMenuIsOpen((prevOpen) => !prevOpen);
  };
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { openCreateForm, close } = useProposalDrawer();
  const refCallback = useCallback(
    (ref) => (virtualListsRefsMap.current[columnId] = ref),
    [virtualListsRefsMap]
  );
  const isHiddenByUserAndEmptyList =
    (isHiddenByUser || isHiddenByUser === undefined) && !list?.length;

  useEffect(() => {
    const proposalColumn = document.querySelector("[data-rbd-droppable-id='proposal-outer']");

    if (proposalColumn) {
      const target = proposalColumn.children[1];

      target.setAttribute('style', 'min-width: 300px;');
      target.classList.add(columnStyles.backgroundMask);

      if (!list.length) {
        target.classList.add(columnStyles.backgroundMaskEmpty);
      }
    }
  }, [list]);

  useEffect(() => {
    setIsHiddenByUser(!!getDataFromLocalStorage(LocalStorageKeys.IS_QUOTES_COLUMN_HIDDEN));
  }, [list]);

  useEffect(() => {
    if (isHiddenByUser && list.length) {
      setDataToLocalStorage(LocalStorageKeys.IS_QUOTES_COLUMN_HIDDEN, false);
    }
  }, [list, isHiddenByUser]);

  if (hidden || isHiddenByUserAndEmptyList) return null;

  return (
    <Draggable draggableId="proposals-column" index={index} isDragDisabled={disabledDrag}>
      {(provided) => (
        <div
          className={clsx(styles.column, styles.proposalColumn)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className={styles.columnHeader}>
            <header
              style={{
                color: columnColor,
                display: 'flex',
                fontSize: 18,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: -2,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{title}</span>
                <div style={{ position: 'relative', padding: '2px 10px', marginLeft: 10 }}>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 11,
                      color: columnColor,
                      lineHeight: 3.5,
                    }}
                  >
                    {list.length}
                  </span>
                  <div
                    style={{
                      borderRadius: 10,
                      position: 'absolute',
                      width: '100%',
                      height: 20,
                      background: columnColor,
                      opacity: 0.3,
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              </div>
              {isRow ? (
                <div className={styles.rowActions}>
                  <button
                    className={styles.action}
                    onClick={() => {
                      openCreateForm({ onSuccess: close });
                    }}
                  >
                    <PersonAddOutlinedIcon />
                    <Typography component={'span'}>{t('duplicates:Add_contact_action')}</Typography>
                  </button>
                  <div className={styles.action}>
                    <ImportExportIcon />
                    <Typography component={'span'}>
                      {t('calls-page:ColumnMenu.export_short')}
                    </Typography>
                  </div>
                  <div className={styles.action}>
                    <ShareIcon />
                    <Typography component={'span'}>
                      {t('calls-page:ColumnMenu.share_short')}
                    </Typography>
                  </div>
                  {!list?.length && (
                    <>
                      <IconButton
                        ref={anchorRef}
                        className={styles.laneActionButton}
                        onClick={toggleMenu}
                        disabled={!isAdmin}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <ProposalColumnMenu
                        isOpen={menuIsOpen}
                        anchorRef={anchorRef}
                        setIsOpen={setMenuIsOpen}
                        setIsHiddenByUser={setIsHiddenByUser}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div style={{ textAlign: 'right', fontSize: 13 }}>
                  <IconButton
                    className={styles.laneActionButton}
                    onClick={() => {
                      trackEvent('Click_add_quote');
                      openCreateForm({ onSuccess: close, type: 'Quote list plus button' });
                    }}
                  >
                    <AddIcon />
                  </IconButton>

                  {!list?.length && (
                    <>
                      <IconButton
                        ref={anchorRef}
                        className={styles.laneActionButton}
                        onClick={toggleMenu}
                        disabled={!isAdmin}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <ProposalColumnMenu
                        isOpen={menuIsOpen}
                        anchorRef={anchorRef}
                        setIsOpen={setMenuIsOpen}
                        setIsHiddenByUser={setIsHiddenByUser}
                      />
                    </>
                  )}
                </div>
              )}
            </header>
          </div>
          <div className={clsx(isRow && styles.tableContainer)} style={{ flexGrow: 1 }}>
            {isRow && list.length > 0 && (
              <thead className={styles.columnTableHeader}>
                <tr>
                  <th>#</th>
                  <th>{t('proposals:Row.name')}</th>
                  <th>{t('proposals:Row.number')}</th>
                  <th>{t('proposals:Row.title')}</th>
                  <th>{t('proposals:Row.sent')}</th>
                  <th>{t('proposals:Row.total')}</th>
                  <th></th>
                </tr>
              </thead>
            )}
            <Droppable
              droppableId={columnId + '-outer'}
              key={columnId + '-outer'}
              type={DragTypes.card}
            >
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={columnStyles.outerDroppable}
                  >
                    <div
                      className={clsx({
                        [columnStyles.placeholder]: true,
                        [columnStyles.visible]: snapshot.isDraggingOver && !isDraggingFromProposal,
                      })}
                    >
                      {t('proposals:Column.drop_here')}
                    </div>
                    {shouldVirtualize ? (
                      <VirtualDroppable
                        visibleProposals={list}
                        columnId={columnId}
                        scrollContainerHeight={scrollContainerHeight}
                        ref={refCallback}
                      />
                    ) : (
                      <StandardDroppable visibleProposals={list} columnId={columnId} />
                    )}
                  </div>
                );
              }}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default memo(ProposalColumn);
