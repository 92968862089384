import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { addColumn } from '../../../slices/boardSlice';
import { useAppDispatch } from '../../../app/hooks';
import { MessageType } from '../../../components/UI/snackbar/providers/SnackBarProvider';

import * as gtag from '../../../../lib/gtag';

import ColumnAttributesForm, {
  ColumnAttributesFormProps,
} from '../../../components/UI/Board/ColumnAttributesForm/ColumnAttributesForm';
import { trackEvent } from 'src/analytics/amplitude';

const CreateColumnForm = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(['common', 'calls-page']);
  const dispatch = useAppDispatch();

  const handleSubmit: ColumnAttributesFormProps['onSubmit'] = async ({ title, color }) => {
    trackEvent('Add_status');
    try {
      await dispatch(addColumn({ title, color })).unwrap();

      gtag.event({
        action: 'new_list',
        category: 'Board',
        label: title,
        value: title,
      });

      onClose();
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };

  return (
    <ColumnAttributesForm
      open={open}
      saveButtonText={t('add_list')}
      inputPlaceholder={t('calls-page:BoardForm.placeholder')}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateColumnForm;
