import { useAppSelector } from '../../../../app/hooks';
import { selectContactsWithoutArchived, filterByAnchors } from '../../../../slices/contactsSlice';
import { selectCurrentWorkspace, selectIsAdminOrOwner } from 'slices/workspacesSlice';
import { selectUserProfile } from 'slices/userSlice';

import ContactsDialog, {
  ContactsDialogProps,
} from '../../../../components/UI/Dialogs/ContactsDialog';

export default function ContactsDialogWrapper(props: Omit<ContactsDialogProps, 'contacts'>) {
  const contacts = useAppSelector(selectContactsWithoutArchived);

  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const { email } = useAppSelector(selectUserProfile);

  const filteredContacts = filterByAnchors(contacts).filter((contact) => {
    const hasAccessToEditContactTags =
      isAdmin || currentWorkspace.memberAccessAllContacts || contact.assigned_to === email;

    return hasAccessToEditContactTags;
  });

  return <ContactsDialog contacts={filteredContacts} {...props} />;
}
