import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import boardStyles from '../../../../styles/calls/board.module.css';
import styles from './ProposalCard.module.css';
import Avatar from '../../../../components/UI/Avatar';
import ContactCard from '../../../../components/UI/ContactCard';
import BoardCard from '../../../../components/UI/Board/BoardCard/BoardCard';
import { useAppSelector } from '../../../../app/hooks';
import { selectContactsMap } from 'slices/contactsSlice';
import { calcTotal } from 'slices/proposalsSlice';
import { selectIsCurrentWorkspaceCollaborative } from 'slices/workspacesSlice';
import useFormatNumber from 'src/app/hooks/ui/useFormatNumber';
import { useAppDispatch } from 'src/app/hooks';
import { setCurrentContact } from 'slices/contactsSlice';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import DirectedText from '../../../../features2/localization/ui/DirectedText';
import contactService from '../../../../services/contacts/contactService';
const ProposalCard = ({
  proposalData,
  onContextMenu,
  menuIsOpen,
}: {
  proposalData: ProposalEntity;
  onContextMenu: (x: number, y: number) => void;
  menuIsOpen: boolean;
}) => {
  const { t } = useTranslation('proposals');
  const contacts = useAppSelector(selectContactsMap);
  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const relatedContact = contacts[proposalData.contactId];
  const { formatNumber } = useFormatNumber();
  const dispatch = useAppDispatch();
  const { total } = calcTotal({
    items: proposalData.items,
    includesTax: proposalData.settings.includesTax,
    taxRate: proposalData.settings.taxRate,
    discountAmount: proposalData.discountAmount,
    discountType: proposalData.discountType,
  });

  return (
    <BoardCard
      onContextMenu={(e) => {
        e.preventDefault();
        onContextMenu(e.clientX, e.clientY);
      }}
      className={clsx(
        boardStyles.BoardCard,
        menuIsOpen && boardStyles.BoardCardMenuOpen,
        styles.BoardCard
      )}
    >
      <ContactCard
        onClick={() => {
          dispatch(setCurrentContact(relatedContact.uuid));
        }}
        className={styles.ContactCard}
      >
        <Avatar
          thumbnail={relatedContact.thumbnail}
          name={relatedContact.suggestion_name || relatedContact.name}
          size={32}
          className={styles.Avatar}
        />
        <div className={styles.content}>
          <div className={styles.mainContent}>
            <div className={styles.name}>
              <span className={styles.number}>#{proposalData.number}</span>{' '}
              {contactService.getContactNameWithFormattedPhoneNumber(relatedContact, formatNumber)}
            </div>
            <div className={styles.title}>{proposalData.title || proposalData.client}</div>
            <div className={styles.totalTitle}>
              <DirectedText>
                <span style={{ textAlign: 'left', display: 'block' }}>
                  {t('Card.total')}:{' '}
                  <span className={styles.totalValue}>
                    {proposalData.settings.currency} {total.toFixed(2)}
                  </span>
                </span>
              </DirectedText>
            </div>
          </div>
          <div className={styles.end}>
            <div className={clsx(styles.sendDate, proposalData.isDraft && styles.isDraftLabel)}>
              {proposalData.isDraft
                ? t('Card.draft')
                : new Date(proposalData.sentAt || proposalData.updatedAt).toLocaleDateString(
                    undefined,
                    {
                      month: 'long',
                      day: 'numeric',
                    }
                  )}
            </div>
          </div>
        </div>
      </ContactCard>
      {isCurrentWorkspaceCollaborative && (
        <ContactCard.AssignToSection
          contactId={relatedContact.uuid}
          assignedTo={relatedContact.assigned_to}
        />
      )}
    </BoardCard>
  );
};

export default ProposalCard;
