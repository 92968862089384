import { Droppable, Draggable } from 'react-beautiful-dnd';

import ProposalItem from '../ProposalItem';
import { DragTypes } from '../../index';
import useHasPermissionToDragItem from '../../useHasPermissionToDragItem';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

interface StandardProppable {
  columnId: string;
  visibleProposals: ProposalEntity[];
}

export default function StandardDroppable(props: StandardProppable) {
  const { visibleProposals, columnId } = props;

  const hasPermissionToDragItem = useHasPermissionToDragItem();

  return (
    <Droppable
      droppableId={columnId}
      key={columnId}
      type={DragTypes.card}
      isDropDisabled={true}
      mode="standard"
    >
      {(provided) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              minWidth: 365,
              height: '100%',
            }}
          >
            {visibleProposals.map((item, index) => {
              const isDragDisabled = !hasPermissionToDragItem(item.contactId);

              return (
                <Draggable
                  key={item.uuid}
                  draggableId={item.uuid}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        key={item.uuid}
                        style={provided.draggableProps.style}
                      >
                        <ProposalItem isRow={false} key={item.uuid} proposalData={item} />
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}
