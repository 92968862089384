import React, { useState } from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { PopperProps } from '@material-ui/core/Popper';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import styles from '../../../../styles/calls/callsColumn.module.scss';
import CloseIcon from 'icons/close.svg';
import AllCallsIcon from 'icons/all-calls.svg';
import IncomingIcon from 'icons/incoming.svg';
import OutgoingIcon from 'icons/outgoing.svg';
import MissedIcon from 'icons/missed.svg';
import DeleteIcon from 'icons/delete.svg';
import CheckIcon from 'icons/check-purple.svg';
import DialogClear from '../../Popups';
import { SelectedFilter } from '../CallsColumnMain';
import { trackEvent } from 'src/analytics/amplitude';
import CallEntity from 'src/db/entities/call/CallEntity';

enum FilterValue {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  MISSED = 'MISSED',
}

interface IProps {
  open: boolean;
  onClose: () => void;
  anchorEl: PopperProps['anchorEl'];
  filterValue: SelectedFilter;
  onFilterChange: (value: SelectedFilter) => void;
  calls: CallEntity[];
}

const FilterCallsMenu: React.FC<IProps> = ({
  open,
  onClose,
  anchorEl,
  filterValue,
  calls,
  onFilterChange,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation('calls-page');

  const handleSelect = (type: SelectedFilter) => {
    const Last_activity_filter_type =
      filterValue === FilterValue.INCOMING
        ? 'Incoming calls'
        : filterValue === FilterValue.OUTGOING
        ? 'Outgoing calls'
        : filterValue === FilterValue.MISSED
        ? 'Missed calls'
        : 'All calls';
    trackEvent('Last_activity_filter', { type: Last_activity_filter_type });
    onFilterChange(type);
    onClose();
  };

  const handleClear = () => {
    trackEvent('Last_activity_clear');
    setOpenDialog(true);
  };

  const isItemActive = (value: SelectedFilter) => filterValue === value;

  const getItemClassName = (value: SelectedFilter) =>
    isItemActive(value) ? styles.activeFilterItem : styles.filterItem;

  const currentFilterLabel =
    filterValue === FilterValue.INCOMING
      ? t('CallsMenu.incoming')
      : filterValue === FilterValue.OUTGOING
      ? t('CallsMenu.outgoing')
      : filterValue === FilterValue.MISSED
      ? t('CallsMenu.missed')
      : null;

  return (
    <>
      {filterValue && (
        <div className={styles.filterStrip}>
          {currentFilterLabel}
          <button className={styles.cancelFilterButton} onClick={() => handleSelect(undefined)}>
            <CloseIcon className={styles.cancelFilterIcon} />
          </button>
        </div>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-start'}
        style={{ zIndex: 2 }}
        className={styles.CallsColumnFilter}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList className={styles.callsActions} id="menu-list-grow">
                  <MenuItem
                    className={clsx(
                      typeof filterValue === 'undefined'
                        ? styles.activeFilterItem
                        : styles.filterItem,
                      styles.CallsColumnFilterItem
                    )}
                    onClick={() => handleSelect(undefined)}
                  >
                    <AllCallsIcon />
                    {t('CallsMenu.all_calls')}
                    {typeof filterValue === 'undefined' && (
                      <CheckIcon className={styles.CallsColumnFilterItemCheck} />
                    )}
                  </MenuItem>

                  <div className={styles.CallsFilterDivider}></div>

                  <MenuItem
                    className={clsx(
                      styles.CallsColumnFilterItem,
                      getItemClassName(FilterValue.INCOMING)
                    )}
                    onClick={() => handleSelect(FilterValue.INCOMING)}
                  >
                    <IncomingIcon />
                    {t('CallsMenu.incoming')}
                    {isItemActive(FilterValue.INCOMING) && (
                      <CheckIcon className={styles.CallsColumnFilterItemCheck} />
                    )}
                  </MenuItem>
                  <MenuItem
                    className={clsx(
                      styles.CallsColumnFilterItem,
                      getItemClassName(FilterValue.OUTGOING)
                    )}
                    onClick={() => handleSelect(FilterValue.OUTGOING)}
                  >
                    <OutgoingIcon />
                    {t('CallsMenu.outgoing')}
                    {isItemActive(FilterValue.OUTGOING) && (
                      <CheckIcon className={styles.CallsColumnFilterItemCheck} />
                    )}
                  </MenuItem>
                  <MenuItem
                    className={clsx(
                      styles.CallsColumnFilterItem,
                      getItemClassName(FilterValue.MISSED)
                    )}
                    onClick={() => handleSelect(FilterValue.MISSED)}
                  >
                    <MissedIcon />
                    {t('CallsMenu.missed')}
                    {isItemActive(FilterValue.MISSED) && (
                      <CheckIcon className={styles.CallsColumnFilterItemCheck} />
                    )}
                  </MenuItem>
                  <MenuItem onClick={handleClear}>
                    <DeleteIcon />
                    {t('CallsMenu.clear')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
              <DialogClear calls={calls} open={openDialog} setIsOpen={setOpenDialog} />
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
export default FilterCallsMenu;
