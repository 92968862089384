import React, { useState, memo, useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Droppable, DroppableMode } from 'react-beautiful-dnd';
import { WindowScroller } from 'react-virtualized';
import { VariableSizeList } from 'react-window';
import clsx from 'clsx';

import { useAppSelector } from '../../../app/hooks';
import debounce from 'lodash/debounce';
import Column from './Column/Column';
import CallsColumn from '../CallsColumn/CallsColumnMain';
import ProposalColumn from './Proposals/ProposalColumn/ProposalColumn';
import styles from '../../../styles/calls/board.module.css';
import { selectIsAdminOrOwner, selectProposalsColNextId } from 'slices/workspacesSlice';
import CreateColumnForm from './CreateColumnForm';
import { selectCurrentContact } from 'slices/contactsSlice';
import BoardToolbar from './components/BoardToolbar';
import { DragTypes } from './index';
import ContactDetails from '../../contactDetails';
import QuickSearchNoResults from 'components/UI/QuickSearchNoResults';
import { ColumnModel } from 'src/api/boardAPI';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import BlackStrip from 'src/features2/billing/ui/promos/BlackStrip/BlackStrip';

function ColumnsDroppable({
  droppableMode,
  preparedColumns,
  searchQuery,
  onSearch,
  onFilterByMembers,
  visibleProposals,
  shouldHideProposalCol,
}: {
  droppableMode: DroppableMode;
  preparedColumns: ColumnModel[];
  searchQuery: string;
  onSearch: (query?: string) => void;
  onFilterByMembers: (memberIds: string[]) => void;
  visibleProposals: ProposalEntity[];
  shouldHideProposalCol: boolean;
}) {
  const { t } = useTranslation();
  const [columnsRendered, setIsColumnsRendered] = useState(false);
  const boardItemsCount = preparedColumns.reduce((acc, val) => (acc += val.items.length), 0);
  const isBoardEmpty = boardItemsCount === 0 && !visibleProposals.length;
  const currentContact = useAppSelector(selectCurrentContact);
  const [openForm, setOpenForm] = useState(false);
  const [isBoardRow] = useState(false);
  const [scrollContainerHeight, setScrollContainerHeight] = useState<number | undefined>();
  const shouldVirtualize = droppableMode === 'virtual';
  const scrollElementRef = useRef<HTMLDivElement>();
  const virtualListsRefsMap = useRef<Record<string, VariableSizeList>>({});
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const hasNoPermissionToDragColumns = !isAdmin;
  const proposalsColNextId = useAppSelector(selectProposalsColNextId);
  const handleScroll = ({ scrollTop }: { scrollTop: number }) => {
    const lists = Object.values(virtualListsRefsMap.current);
    if (lists.length > 0) {
      for (const vlist of lists) {
        vlist?.scrollTo(scrollTop);
      }
    }
  };
  useEffect(() => {
    if (!scrollElementRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      setScrollContainerHeight(scrollElementRef.current?.offsetHeight);
    });
    resizeObserver.observe(scrollElementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (scrollElementRef?.current) {
        scrollElementRef.current.scrollTop = 0;
        setScrollContainerHeight(scrollElementRef.current.offsetHeight);
      }
    };

    global.addEventListener('resize', handleResize);
    return () => global.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const scrollElementHeight = scrollElementRef.current?.offsetHeight;
    typeof scrollElementHeight !== 'undefined' && setScrollContainerHeight(scrollElementHeight);
    // scrollElementRef.current && setScrollContainerHeight(scrollElementRef.current.offsetHeight);
  }, [preparedColumns, currentContact, columnsRendered]);

  const searchQueryIncludesProposalCol = t('proposals:sectionName')
    .toLowerCase()
    .includes(searchQuery);
  const isSearchWithoutResults =
    searchQuery.length && preparedColumns.length === 0 && !searchQueryIncludesProposalCol;
  const renderCols = () => {
    const colsToRender: Array<ColumnModel | 'proposals'> = [...preparedColumns];

    if (proposalsColNextId === null) {
      colsToRender.push('proposals');
    } else {
      const index = preparedColumns.findIndex((col) => col.id === proposalsColNextId);

      if (index === -1) {
        colsToRender.push('proposals');
      } else {
        colsToRender.splice(index, 0, 'proposals');
      }
    }
    setIsColumnsRendered(true);
    return colsToRender.map((col, index) => {
      if (col === 'proposals') {
        return (
          <ProposalColumn
            key={index}
            list={visibleProposals}
            hidden={shouldHideProposalCol}
            isRow={isBoardRow}
            index={index}
            shouldVirtualize={shouldVirtualize}
            virtualListsRefsMap={virtualListsRefsMap}
            scrollContainerHeight={scrollContainerHeight}
            disabledDrag={openForm || hasNoPermissionToDragColumns}
          />
        );
      } else {
        return (
          <Column
            isBoardEmpty={isBoardEmpty}
            disabledDrag={openForm || hasNoPermissionToDragColumns}
            index={index}
            column={col}
            key={col.id}
            isRow={isBoardRow}
            shouldVirtualize={shouldVirtualize}
            virtualListsRefsMap={virtualListsRefsMap}
            scrollContainerHeight={scrollContainerHeight}
          />
        );
      }
    });
  };
  useEffect(() => {
    const handleResize = debounce(() => {}, 200);

    global.addEventListener('resize', handleResize);
    return () => global.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {!currentContact && shouldVirtualize && scrollElementRef.current && (
        <WindowScroller onScroll={handleScroll} scrollElement={scrollElementRef.current}>
          {() => {
            return <div />;
          }}
        </WindowScroller>
      )}
      <Droppable
        droppableId={'all-columns'}
        direction={isBoardRow ? 'vertical' : 'horizontal'}
        type={DragTypes.column}
      >
        {(provided) => {
          return (
            <div
              style={{ display: 'flex', height: '100%' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <CallsColumn />
              <div className={styles.boardContainer} ref={scrollElementRef}>
                {currentContact ? (
                  <ContactDetails />
                ) : (
                  <>
                    <BoardToolbar
                      setOpenForm={setOpenForm}
                      onFilter={onFilterByMembers}
                      onSearch={onSearch}
                    />

                    <BlackStrip />

                    <div
                      className={clsx(
                        isBoardRow ? styles.columnsWrapperRow : styles.columnsWrapper
                      )}
                    >
                      {isSearchWithoutResults && shouldHideProposalCol ? (
                        <QuickSearchNoResults />
                      ) : (
                        <>
                          {scrollContainerHeight && renderCols()}
                          {openForm && (
                            <CreateColumnForm
                              open={openForm}
                              onClose={() => {
                                setOpenForm(false);
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {provided.placeholder}
                  </>
                )}
              </div>
            </div>
          );
        }}
      </Droppable>
    </>
  );
}

export default memo(ColumnsDroppable);
