import { useState, memo } from 'react';
import ProposalRow from './ProposalRow';
import ProposalCard from './ProposalCard';
import ProposalCardMenu from './ProposalCardMenu';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

const ProposalItem = ({
  isRow,
  proposalData,
}: {
  isRow: boolean;
  proposalData: ProposalEntity;
}) => {
  const [position, setPosition] = useState({ mouseX: null, mouseY: null });
  const menuIsOpen = !!position.mouseX && position.mouseY;

  const openContextMenu = (clickX: number, clickY: number) => {
    setPosition({
      mouseX: clickX - 2,
      mouseY: clickY - 4,
    });
  };

  const Component = isRow ? ProposalRow : ProposalCard;

  return (
    <>
      <Component
        proposalData={proposalData}
        onContextMenu={openContextMenu}
        menuIsOpen={menuIsOpen}
      />
      <ProposalCardMenu
        contactUuid={proposalData.contactId}
        uuid={proposalData.uuid}
        position={position}
        onClose={() => setPosition({ mouseX: null, mouseY: null })}
      />
    </>
  );
};

export default memo(ProposalItem);
