import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useTranslation } from 'next-i18next';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';

import RemoveIcon from '../../../../../public/images/icons/remove-items.svg';
import styles from '../../../../styles/calls/board.module.css';
import { setDataToLocalStorage } from '../../../../utils';
import LocalStorageKeys from '../../../../enums/LocalStorageKeys';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsHiddenByUser: Dispatch<SetStateAction<boolean>>;
  anchorRef: MutableRefObject<HTMLButtonElement>;
}

const ColumnMenu: React.FC<IProps> = ({ isOpen, setIsOpen, anchorRef, setIsHiddenByUser }) => {
  const { t } = useTranslation('proposals');
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setIsOpen(false);
  };

  const onHideClick = () => {
    setIsHiddenByUser(true);
    setDataToLocalStorage(LocalStorageKeys.IS_QUOTES_COLUMN_HIDDEN, true);
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement={'bottom-start'}
      style={{ zIndex: 2 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={styles.columnMenu}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                <MenuItem onClick={onHideClick}>
                  <RemoveIcon />
                  {t('Column.Menu.hide')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
export default ColumnMenu;
