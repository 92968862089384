import { memo, useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '../../../../../public/images/icons/search.svg';
import { Toolbar } from '@material-ui/core';
import styles from '../../../../styles/calls/board.module.css';
import debounce from 'lodash/debounce';
import AvatarsFilter from 'components/UI/Inputs/AvatarsFilter';
import { useAppSelector } from '../../../../app/hooks';

import {
  selectAcceptedTeamMembers,
  selectCurrentWorkspace,
  selectIsAdminOrOwner,
} from '../../../../slices/workspacesSlice';
import { useTranslation } from 'next-i18next';
import ButtonPrimary from 'components/UI/Buttons/ButtonPrimary';
import AddTeammembersButton from 'components/UI/Buttons/functional/AddTeammembersButton';

type ToolbarT = {
  setOpenForm: Dispatch<SetStateAction<boolean>>;
  onSearch: (query?: string) => void;
  onFilter: (members: string[]) => void;
};

const BoardToolbar = ({ setOpenForm, onSearch, onFilter }: ToolbarT) => {
  const { t } = useTranslation('calls-page');
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const members = useAppSelector(selectAcceptedTeamMembers);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);
  const [query, setQuery] = useState('');
  const debouncedSearchHandler = useRef(
    debounce((query) => {
      return onSearch(query);
    }, 500)
  );

  useEffect(() => {
    debouncedSearchHandler.current(query);
  }, [query]);

  return (
    <Toolbar className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        <InputBase
          startAdornment={<SearchIcon className={styles.searchIcon} />}
          className={styles.boardSearch}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder={t('common:quick_search_placeholder')}
          inputProps={{ 'data-hj-allow': '' }}
        ></InputBase>
        {members.length > 1 && (memberAccessAllContacts || isAdmin) && (
          <AvatarsFilter items={members} onChange={onFilter} />
        )}
        {/* TBD: CHANGE LAYOUT FOR BOARD */}
        {/* <SortSwitcher onClick={handleFilter} checked={isBoardRow} /> */}
      </div>
      <div className={styles.toolbarRight}>
        {isAdmin && (
          <>
            <AddTeammembersButton className={styles.inviteTeammembersButton} />
            <ButtonPrimary
              variant={'add'}
              text={t('Toolbar.columnForm.button')}
              onClick={() => setOpenForm((prevState) => !prevState)}
            />
          </>
        )}
      </div>
    </Toolbar>
  );
};
export default memo(BoardToolbar);
