import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import orderBy from 'lodash/orderBy';
import styles from '../../../styles/calls/callsColumn.module.scss';
import CallsColumnEmpty from './CallsColumnEmpty';
import CallsColumnFilled from './CallsColumnFilled';
import { useAppSelector, useAppStore } from '../../../app/hooks';
import { selectCalls } from '../../../slices/callsSlice';
import {
  selectNotHiddenContacts,
  getPhoneNumbersMapByContactsIds,
  filterByAnchors,
  selectContactsMap,
} from '../../../slices/contactsSlice';
import {
  selectCurrentUserMemberData,
  selectCurrentWorkspace,
  selectIsAdminOrOwner,
  selectIsCurrentWorkspaceCollaborative,
} from '../../../slices/workspacesSlice';
import FilterCallsMenu from './components/FilterCallsMenu';
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'next-i18next';
import { selectUserHasApp } from 'slices/userSlice';
import { trackEvent } from 'src/analytics/amplitude';
import CallsContactsColumn from 'components/UI/CallsContactsColumn';
import LocalStorageKeys from '../../../enums/LocalStorageKeys';
import CallEntity from 'src/db/entities/call/CallEntity';

export type SelectedFilter = undefined | ('INCOMING' | 'OUTGOING' | 'MISSED');

const MAX_RECENTS_TO_SHOW = 25;

const CallsColumn = () => {
  const isCollaborativeWorkspace = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const isAppDownloaded = useAppSelector(selectUserHasApp);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const calls = useAppSelector(selectCalls);
  const contacts = useAppSelector(selectNotHiddenContacts);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const { email } = useAppSelector(selectCurrentUserMemberData);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();
  const store = useAppStore();

  useEffect(() => {
    trackEvent('Last_activity_download_app', { state: !isAppDownloaded });
  }, [isAppDownloaded]);

  const recentCalls = useMemo(() => {
    const filteredContacts = filterByAnchors(contacts);
    const contactsMap = getPhoneNumbersMapByContactsIds(filteredContacts);
    const contactsMapById = selectContactsMap(store.getState());
    const filteredByRole: CallEntity[] = calls.filter((callItem) => {
      const relatedContactId = contactsMap[callItem.normalizedPhone];
      if (!relatedContactId) return true;
      const relatedContact = contactsMapById[relatedContactId];
      return (
        !relatedContact ||
        isAdmin ||
        memberAccessAllContacts ||
        relatedContact.assigned_to === email
      );
    });
    const sortedCalls = orderBy<CallEntity>(filteredByRole, 'callDate', 'desc');
    const uniqCalls = uniqBy<CallEntity>(sortedCalls, 'normalizedPhone');
    const visibleCalls = uniqCalls.filter(({ notShow }) => !notShow);
    if (selectedFilter) {
      const filteredCalls = visibleCalls
        .filter((call) => {
          if (selectedFilter === 'MISSED') {
            return call.type === 'MISSED' || call.type === 'REJECTED';
          } else {
            return call.type === selectedFilter;
          }
        })
        .slice(0, MAX_RECENTS_TO_SHOW - 1);

      return uniqBy(filteredCalls, 'normalizedPhone');
    } else {
      return visibleCalls.slice(0, MAX_RECENTS_TO_SHOW - 1);
    }
  }, [calls, selectedFilter, contacts, email, isAdmin, memberAccessAllContacts, store]);

  const handleToggleMenu = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleSelectFilter = (nextValue: SelectedFilter) => {
    setSelectedFilter((prevValue) => (prevValue === nextValue ? undefined : nextValue));
  };

  const { t } = useTranslation('calls-page');

  return (
    <CallsContactsColumn
      columnKey={LocalStorageKeys.IS_CALLS_COLUMN_EXPANDED}
      className={styles.wrapper}
    >
      {(isExpanded, setIsExpanded) => (
        <Container
          style={{ visibility: !isExpanded ? 'collapse' : 'visible' }}
          classes={{ root: styles.container }}
        >
          {isExpanded && (
            <>
              <div className={styles.header}>
                <Typography
                  ref={anchorRef}
                  className={styles.columnTitle}
                  variant={'h3'}
                  onClick={handleToggleMenu}
                >
                  {t('CallsMenu.title')}
                  <IconButton size="small" className={styles.filterButton}>
                    {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Typography>
                {isCollaborativeWorkspace && (
                  <div className={styles.collaborationLabel}>
                    {t('CallsMenu.collaboration_label')}
                  </div>
                )}
              </div>
              <FilterCallsMenu
                calls={recentCalls}
                filterValue={selectedFilter}
                onFilterChange={handleSelectFilter}
                open={menuOpen}
                anchorEl={anchorRef.current}
                onClose={() => {
                  setMenuOpen(false);
                }}
              />
            </>
          )}
          {recentCalls.length ? <CallsColumnFilled calls={recentCalls} /> : <CallsColumnEmpty />}
        </Container>
      )}
    </CallsContactsColumn>
  );
};
export default memo(CallsColumn);
