import React, { useEffect, useState, createContext } from 'react';
import { DragDropContext, DropResult, DragStart, DroppableMode } from 'react-beautiful-dnd';
import { useTranslation } from 'next-i18next';

import styles from '../../../styles/calls/board.module.css';

import { selectUserProfile } from '../../../slices/userSlice';
import { selectIsAdminOrOwner, selectCurrentWorkspace } from '../../../slices/workspacesSlice';
import { selectContactsMap, selectArchivedContacts } from '../../../slices/contactsSlice';
import {
  selectSyncFailed,
  makeSelectBoardColumnsWithVisibleContacts,
} from '../../../slices/boardSlice';
import { selectVisibleProposals } from '../../../slices/proposalsSlice';
import { useAppDispatch, useAppSelector, useAppStore } from '../../../app/hooks';
import ErrorState from './ErrorState';
import useUpdateBoardByDrag from './useUpdateBoardByDrag';
import ColumnsDroppable from './ColumnsDroppable';
import useSearch from './Search/useSearch';
import useSearchProposals from './Search/useSearchProposals';
import DefaultDialog from '../../../components/UI/Dialogs/DefaultDialog/DefaultDialog';
import useReassignCallDialogState from './useReassignCallDialogState';

import { setCurrentContact } from 'slices/contactsSlice';

export enum DragTypes {
  column = 'column',
  card = 'card',
  proposal = 'proposal',
}

export const DraggingContext = createContext<DragStart | undefined>(undefined);

const CustomBoard = () => {
  const { t } = useTranslation('calls-page');

  const dispatch = useAppDispatch();
  const store = useAppStore();

  const syncFailed = useAppSelector(selectSyncFailed);

  const [dragState, setDragState] = useState<DragStart | undefined>(undefined);

  const {
    callReassingDialogOpen,
    handleConfirm,
    checkShouldReassignCall,
    closeReassignCallDialog,
    description,
  } = useReassignCallDialogState();

  useEffect(() => {
    return () => {
      dispatch(setCurrentContact(null));
    };
  }, [dispatch]);

  const columns = useAppSelector(makeSelectBoardColumnsWithVisibleContacts);
  const contactsMap = useAppSelector(selectContactsMap);

  const archivedContacts = useAppSelector(selectArchivedContacts);

  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const { email } = useAppSelector(selectUserProfile);

  const { selection, setSearchQuery, searchQuery } = useSearch(columns);
  const [filteredMembers, setFilteredMembers] = useState<string[]>([]);

  const { visibleProposals, shouldHideColumn } = useSearchProposals({
    searchQuery,
    filteredMembers,
  });

  const updateBoardByDrag = useUpdateBoardByDrag();

  const handleDragEnd = async (result: DropResult) => {
    await checkShouldReassignCall(result);
    updateBoardByDrag(result, preparedColumns, shouldHideColumn);
  };

  const getPreparedColumns = () => {
    let result = searchQuery.length ? selection : columns;

    const shouldFilterByMemberRole = !isAdmin && !currentWorkspace?.memberAccessAllContacts;

    if (shouldFilterByMemberRole) {
      result = result.map((col) => {
        const filteredItems = col.items.filter((item) => {
          const relatedContact = contactsMap[item.typeUuid];

          return relatedContact?.assigned_to === email;
        });
        return {
          ...col,
          items: filteredItems,
        };
      });
    }

    if (filteredMembers.length) {
      result = result.map(({ items, ...columnData }) => {
        return {
          ...columnData,
          items: items.filter(({ typeUuid }) => {
            const contact = contactsMap[typeUuid];
            return filteredMembers.some((teammember) => teammember === contact?.assigned_to);
          }),
        };
      });
    }

    if (archivedContacts.length > 0) {
      result = result.map(({ items, ...columnData }) => {
        return {
          ...columnData,
          items: items.filter(({ typeUuid }) => {
            const contact = contactsMap[typeUuid];

            if (!contact) {
              return false;
            }

            return !contact.is_archived;
          }),
        };
      });
    }

    return result;
  };

  const preparedColumns = getPreparedColumns();

  const [droppableMode, setDroppableMode] = useState<DroppableMode | undefined>();

  // this effect is used to enable virtual scrolling for the board when necessary
  // it executes only once when the board data is loaded
  useEffect(() => {
    const state = store.getState();

    const boardItemsQty = makeSelectBoardColumnsWithVisibleContacts(state).reduce(
      (acc, colItem) => {
        return acc + colItem.items.length;
      },
      0
    );
    const proposalsQty = selectVisibleProposals(state).length;
    const totalQty = boardItemsQty + proposalsQty;

    if (totalQty > 100) {
      setDroppableMode('virtual');
    } else {
      setDroppableMode('standard');
    }
  }, [store]);

  if (syncFailed) {
    return <ErrorState />;
  }

  if (!droppableMode) {
    return null;
  }
  return (
    <>
      <DraggingContext.Provider value={dragState}>
        <div className={styles.boardWrapper}>
          <DragDropContext
            onBeforeDragStart={(val) => {
              setDragState(val);
            }}
            onDragEnd={(result) => {
              return handleDragEnd(result);
            }}
          >
            <ColumnsDroppable
              searchQuery={searchQuery}
              droppableMode={droppableMode}
              preparedColumns={preparedColumns}
              onSearch={setSearchQuery}
              onFilterByMembers={setFilteredMembers}
              visibleProposals={visibleProposals}
              shouldHideProposalCol={shouldHideColumn}
            />
          </DragDropContext>
        </div>
      </DraggingContext.Provider>
      <DefaultDialog
        open={callReassingDialogOpen}
        onClose={closeReassignCallDialog}
        onSuccess={handleConfirm}
        title={t('reassign_call_dialog.title')}
        description={description}
        confirmText={t('reassign_call_dialog.confirm')}
      />
    </>
  );
};

export default CustomBoard;
